import { gql, useMutation } from "@apollo/client";
import React, { useState } from "react";
import { Popup, Form, Button } from "semantic-ui-react";
import Swal from "sweetalert2";

const UpdateEmail = ({ id }) => {
  const [email, setEmail] = useState("");
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const onCompleted = () => {
    setLoading(false);
    setEmail("");
    Swal.fire({
      icon: 'success',
      title: 'Completado',
      text: "Correo actualizado exitosamente."
    });
  }

  const onError = ({ graphQLErrors }) => {
    setLoading(false);
    if (graphQLErrors) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: graphQLErrors[0]?.message
      });
    } else {
      Swal.fire("Lo sentimos, ha ocurrido un error.");
    }
  };
  const [mutation] = useMutation(UPDATE_EMAIL, { onCompleted, onError });

  const handleMutation = () => {
    setLoading(true);
    mutation({
      variables: {
          id,
          email
      }
    })
  }

  return (
    <Popup
      on='click'
      open={open}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      trigger={<Button size="mini" content='Cambiar correo' />}
      wide
    >
      <Form>
        <Form.Input
          onChange={(e, { value }) => setEmail(value)}
          value={email} />
        <Form.Button
          loading={loading}
          disabled={!email.trim() || loading}
          onClick={handleMutation}
          content="Guardar" />
      </Form>
    </Popup>
  );
}

const UPDATE_EMAIL = gql`
  mutation updateUser($id: ID! $email: EmailAddress!){
    actualizarCorreo(id:$id email: $email){
      id
      email
    }
  }
`;

export default UpdateEmail;