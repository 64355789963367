import { gql, useQuery } from "@apollo/client";
import { useState } from "react";
import { Button, Form } from "semantic-ui-react";
import CustomTable from "../../../Components/CustomTable";
import CreateTemplate from "./Create";
import DeleteTemple from "./Delete";
import UpdateTemple from "./update";


const options = [
    { text: "Activo", value: "ACTIVO", key: "ACTIVO" },
    { text: "Eliminado", value: "ELIMINADO", key: "ELIMINADO" },
];

const Templates = ({ permissions }) => {
    const [status, setStatus] = useState("ACTIVO");
    const [name, setName] = useState("");
    const [fakeName, setFakeName] = useState("");

    const filtro = { estado: status };

    if (name) filtro.nombre = name;

    const { loading, error, data, refetch } = useQuery(
        GET_TEMPLATES,
        {
            variables: { filtro },
            fetchPolicy: "network-only"
        });


    const CREATE = permissions?.some(value => value?.permiso.nombre === "Crear");
    const EDIT = permissions?.some(value => value?.permiso.nombre === "Editar");
    const DELETE = permissions?.some(value => value?.permiso?.nombre === "Eliminar");

    const columns = [
        {
            header: 'Nombre',
            accessor: 'nombre'
        },
        {
            header: 'Contenido',
            accessor: 'contenido'
        },
        {
            header: 'Estado',
            accessor: 'estado'
        }
    ];

    if (EDIT) columns.push(
        {
            header: 'Acción de editar',
            render: data => (
                <UpdateTemple
                  template={data}
                  reload={refetch}
                  trigger={
                    <Button
                      style={{ backgroundColor: "#045A73", color: "white" }}
                      size="mini"
                      icon="edit"
                      content="Editar" />
                  } />
              )
        }
    );

    if (DELETE && status !== "ELIMINADO") columns.push(
        {
            header: 'Acción de eliminar',
            render: data => (
                <DeleteTemple
                  id={data.id}
                  reload={refetch}
                />
              )
        }
    );

    let listData = data?.multiPlantillas?.items.map(item =>{
        let contenido = ""
        const detalle = item.detalle;
        if (detalle.length>135) {
            const contect = detalle.split("");
            for (let index = 0; index <= 135; index++) {
                contenido = `${contenido}${contect[index]}`;
            }
            contenido=`${contenido}...`
        }else{
            contenido=detalle
        }
        return {
            ...item,
            contenido
        }
    })

    return (
        <div style={{marginRight:'2%'}}>
            <h1>Plantillas</h1>
            <Form>

                <Form.Group inline>
                    {
                        CREATE && <CreateTemplate reload={refetch} />
                    }
                    <Form.Dropdown
                        selection
                        value={status}
                        onChange={(e, { value }) => setStatus(value)}
                        options={options} />
                    <Form.Input
                        action={{
                            icon: 'search',
                            content: 'Buscar',
                            onClick: () => setName(fakeName)
                        }}
                        value={fakeName}
                        onChange={(e, { value }) => setFakeName(value)}
                        placeholder="Búsqueda por nombre" />
                </Form.Group>
            </Form>
            <CustomTable
                data={listData}
                loading={loading}
                error={error}
                columns={columns} />
        </div>
    );
};

const GET_TEMPLATES = gql`
  query getTemplates($filtro: MultiPlantillaFiltro!) {
    multiPlantillas(filtro: $filtro) {
        items{
            id
            nombre
            detalle
            estado
        }
    }
  }
`;

export default Templates;

