import { gql, useQuery } from "@apollo/client";
import React, { useState } from "react";
import { Breadcrumb, Button, Form, Icon, Label } from "semantic-ui-react";
import CustomTable from "../../../../Components/CustomTablePagination";
import DocumentResponse from "../../../../Components/DocumentResponse";
import moment from "moment";
import Management from "./Management";
import ManagementHistory from "./ManagementHistory";
import CustomXls from "../../../../Components/CustomXlsx";
import FormFilter from "../../../../Components/FormFilter";
import AgentFilter from "./AgentFilter";
import { uri } from "../../../../client";
import Swal from "sweetalert2";

const RequestReport = () => {
  const [origin, setOrigin] = useState("");
  const [status] = useState("ACTIVO");
  const [form, setForm] = useState("");
  const [agent, setAgent] = useState("");
  const [loadingRefetch, setLoadingRefetch] = useState(false);
  const [requestStatus, setRequestStatus] = useState("");
  const [id, setId] = useState({ value: "", fakeValue: "" });
  const [document, setDocument] = useState({ value: "", fakeValue: "" });
  const [documentName, setDocumentName] = useState({
    value: "",
    fakeValue: "",
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(10);
  const filtro = { estado: status };
  if (id.value.trim()) filtro.id = id.value;
  if (document.value.trim()) filtro.documentoUsuario = document.value;
  if (documentName.value.trim()) filtro.nombreDocumento = documentName.value;
  if (origin) filtro.idProcedencia = origin;
  if (form) filtro.idDocumento = form;
  if (agent) filtro.idAgente = agent;
  if (requestStatus) filtro.idEstadoSolicitud = requestStatus;
  const { loading, error, data, refetch } = useQuery(REQUEST_REPORT, {
    variables: { pagina: { number: currentPage, limit: pageLimit }, filtro },
    fetchPolicy: "network-only",
  });

  const columns = [
    {
      header: "Id",
      accessor: "id",
    },
    {
      header: "Foto",
      accessor: "usuario.url_imagen",
      render: (url_imagen) => (
        <>
          {url_imagen !== null ? (
            <img
              src={uri + url_imagen}
              alt="foto"
              width="100"
              height="100"
              style={{ borderRadius: "50%" }}
            />
          ) : (
            <Icon style={{ color: "#B0193F" }} name="user" size="huge" />
          )}
        </>
      ),
    },
    {
      header: "Nombre",
      accessor: "usuario.nombre",
    },
    {
      header: "No. Documento",
      accessor: "usuario.documento",
    },
    {
      header: "Formulario",
      accessor: "documento.nombre",
    },
    {
      header: "Procedencia",
      accessor: "usuario.valorProcedencia.valorParametro",
    },
    {
      header: "Estado solicitud",
      accessor: "estadoSolicitud.valorParametro",
    },
    {
      header: "fecha de creación",
      accessor: "fechaCreacion",
      render: (date) => moment(date).format("DD-MM-YYYY h:mm:ss A"),
    },
    {
      header: "fecha de modificación",
      accessor: "fechaActualizacion",
      render: (date) =>
        date ? moment(date).format("DD-MM-YYYY h:mm:ss A") : "",
    },
    {
      header: "Mensaje de solicitud",
      accessor: "",
      render: (object) => {
        return (
          <>
            {["13", "14", "113"].includes(object.estadoSolicitud.id) && (
              <Button
                style={{ backgroundColor: "#045A73", color: "white" }}
                size="mini"
                onClick={() =>
                  Swal.fire({
                    icon: "info",
                    title: "Mensaje de solicitud",
                    html:
                      object.estadoSolicitud.id == "113"
                        ? object.mensajeSolicitudEnTramite
                        : object.estadoSolicitud.id == "13"
                        ? object.mensajeSolicitudAprobada
                        : object.mensajeSolicitudRechazada,
                    confirmButtonText: "Aceptar",
                  })
                }
              >
                Mostrar mensaje
              </Button>
            )}
          </>
        );
      },
    },
    {
      header: "Detalle",
      accessor: "id",
      render: (id) => (
        <DocumentResponse
          trigger={
            <Button
              size="mini"
              style={{ backgroundColor: "#045A73", color: "white" }}
              icon="arrow alternate circle right"
            />
          }
          id={id}
        />
      ),
    },
    {
      header: "Historial de la solicitud",
      render: (request) =>
        request?.checkHistorial && <ManagementHistory id={request?.id} />,
    },
    {
      header: "Gestionar",
      render: (request) => (
        <Management
          id={request?.id}
          statusRequest={request?.estadoSolicitud?.valorParametro}
          options={data?.estadoSolicitud || []}
          trigger={
            <Button
              size="mini"
              style={{ backgroundColor: "#045A73", color: "white" }}
              icon="edit"
            />
          }
        />
      ),
    },
    {
      header: "Observación",
      accessor: "observacion",
    },
  ];

  const cleanFilter = () => {
    setId({ value: "", fakeValue: "" });
    setOrigin("");
    setForm("");
    setAgent("");
    setRequestStatus("");
    setDocumentName({ value: "", fakeValue: "" });
    setDocument({ value: "", fakeValue: "" });
  };

  const handleRefetch = async () => {
    setLoadingRefetch(true);
    await refetch();
    setLoadingRefetch(false);
  };

  const sections = [
    { key: "Solicitudes", content: "Solicitudes", active: true },
  ];

  return (
    <>
      <Breadcrumb size="massive" icon="right angle" sections={sections} />
      <br />
      <br />
      <Form>
        <Form.Group inline>
          <Form.Field>
            <Label
              content={`Cantidad (${data?.multiSolicitudes?.count || 0})`}
            />
          </Form.Field>
          <Form.Dropdown
            clearable
            placeholder="Filtro de estado solicitud"
            loading={loading}
            error={error}
            label="Estado solicitud"
            value={requestStatus}
            onChange={(e, { value }) => setRequestStatus(value)}
            options={data?.estadoSolicitud || []}
          />
          <Form.Dropdown
            clearable
            placeholder="Filtro de procedencia"
            loading={loading}
            error={error}
            label="Procedencia"
            value={origin}
            onChange={(e, { value }) => setOrigin(value)}
            options={data?.procedencia || []}
          />
          <FormFilter
            value={form}
            onChange={(e, { value }) => setForm(value)}
          />
          <AgentFilter
            value={agent}
            onChange={(e, { value }) => setAgent(value)}
          />
        </Form.Group>
        <Form.Group inline>
          <Form.Input
            type="number"
            size="mini"
            value={id?.fakeValue}
            onChange={(e, { value }) => setId({ ...id, fakeValue: value })}
            action={
              <Button
                onClick={() => setId({ ...id, value: id.fakeValue })}
                size="mini"
                icon="search"
                content="Id"
              />
            }
            placeholder="Buscar por id"
          />
          <Form.Input
            size="mini"
            value={document?.fakeValue}
            onChange={(e, { value }) =>
              setDocument({ ...document, fakeValue: value })
            }
            onKeyPress={(e) =>
              e.key === "Enter" &&
              setDocument({ ...document, value: document.fakeValue })
            }
            action={
              <Button
                onClick={() =>
                  setDocument({ ...document, value: document.fakeValue })
                }
                size="mini"
                icon="search"
                content="No. Documento"
              />
            }
            placeholder="Buscar por número documento"
          />
          {/* <Form.Input
            size="mini"
            value={documentName?.fakeValue}
            onChange={(e, { value }) => setDocumentName({ ...documentName, fakeValue: value })}
            action={<Button onClick={() => setDocumentName({ ...documentName, value: documentName.fakeValue })} size="mini" icon="search" content="Formulario" />}
            placeholder='Buscar por formulario' />*/}
          <CustomXls
            columns={columns}
            fileName="Solicitudes"
            data={data?.multiSolicitudes.items || []}
          />
          <Form.Button
            icon="undo"
            content="Actualizar"
            loading={loadingRefetch}
            size="mini"
            onClick={handleRefetch}
          />
          <Form.Button
            compact
            icon="trash"
            onClick={cleanFilter}
            size="mini"
            content="Vaciar filtros"
          />
        </Form.Group>
      </Form>
      <CustomTable
        currentPage={currentPage}
        onPageChange={(e, { activePage }) => setCurrentPage(activePage)}
        pageLimit={pageLimit}
        setPageLimit={setPageLimit}
        setCurrentPage={setCurrentPage}
        totalPages={data?.multiSolicitudes?.totalPages || 0}
        data={data?.multiSolicitudes.items || []}
        loading={loading || loadingRefetch}
        error={error}
        columns={columns}
      />
    </>
  );
};

const REQUEST_REPORT = gql`
  query requestReport($filtro: MultiSolicitudFiltro!, $pagina: PageInput!) {
    procedencia: multiValorParametros(
      filtro: { estado: ACTIVO, idParametro: 1 }
    ) {
      value: id
      text: valorParametro
      key: id
    }
    estadoSolicitud: multiValorParametros(
      filtro: { estado: ACTIVO, idParametro: 4 }
      orden: { direccion: ASC, campo: ORDEN }
    ) {
      value: id
      text: valorParametro
      key: id
    }
    multiSolicitudes(
      filtro: $filtro
      orden: { direccion: ASC, campo: FECHA_CREACION }
      pagina: $pagina
    ) {
      page {
        number
        limit
      }
      count
      totalPages
      items {
        id
        observacion
        checkHistorial
        fechaCreacion
        mensajeSolicitudEnTramite
        mensajeSolicitudAprobada
        mensajeSolicitudRechazada
        fechaActualizacion
        estadoSolicitud {
          id
          valorParametro
        }
        documento {
          id
          nombre
        }
        usuario {
          id
          valorProcedencia {
            id
            valorParametro
          }
          documento
          nombre
          url_imagen
        }
      }
    }
  }
`;

export default RequestReport;
