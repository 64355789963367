import React, { useState } from "react";
import { Card, Menu, Message, Pagination, Placeholder, Segment } from "semantic-ui-react";


const CustomCardList = ({ error, loading, componentError, data = [], children }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 10;
  const totalPages = Math.ceil(data.length / pageSize);
  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  if (error) {
    if (componentError) return componentError;
    return (
      <div style={{ overflowX: 'overlay' }}>
        <Message>Ha ocurrido un error.</Message>
      </div>
    );
  }

  if (loading) return (
    <Card.Group stackable>
      {[...Array(4)].map((_, key) => (
        <Card key={key}>
          <Segment raised>
            <Placeholder>
              <Placeholder.Header image>
                <Placeholder.Line />
                <Placeholder.Line />
              </Placeholder.Header>
              <Placeholder.Paragraph>
                <Placeholder.Line length='medium' />
                <Placeholder.Line length='short' />
              </Placeholder.Paragraph>
            </Placeholder>
          </Segment>
        </Card>
      ))}
    </Card.Group>
  );

  return (
    <div>
      {children({ list: data.slice(startIndex, endIndex) })}
      <br />
      <br />
      <Menu pagination>
        <Pagination
          boundaryRange={0}
          defaultActivePage={currentPage}
          ellipsisItem={null}
          onPageChange={(e, { activePage }) => setCurrentPage(activePage)}
          firstItem={null}
          lastItem={null}
          siblingRange={1}
          totalPages={totalPages}
        />
      </Menu>
    </div>
  )

}

export default CustomCardList;