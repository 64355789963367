import { gql, useQuery } from "@apollo/client";
import React from "react";
import { Form, Grid, Header, Icon, Message, Modal, Segment } from "semantic-ui-react";
import InputsResponse from "./InputsResponse";
import PlaceholderMultiLine from "./PlaceholderMultiLine";
import ReactMarkdown from "react-markdown";
import gfm from "remark-gfm";
const DocumentResponse = ({ id, trigger, type = "request", idDocumento }) => (
  <Modal dimmer="inverted" size="large" trigger={trigger}>
    <Content id={id} type={type} idDocumento={idDocumento} />
  </Modal>
);

const Content = ({ id, type, idDocumento }) => {
  const variables = { [type === 'request' ? 'idSolicitud' : 'idPago']: id, idDocumento };
  const { loading, error, data } = useQuery(DOCUMENT_RESPONSE, { variables });

  if (loading) return (
    <Modal.Content>
      <PlaceholderMultiLine numberLine={10} />
    </Modal.Content>
  );

  if (error) return (
    <Modal.Content>
      <Message negative>
        <Message.Header>Lo sentimos, ha ocurrido un error.</Message.Header>
      </Message>
    </Modal.Content>
  );

  const { multiRespuesta } = data;


  return (
    <>
      <Modal.Header>
        <Icon name="pencil" />
        Respuesta del documento
      </Modal.Header>
      <Modal.Content scrolling>
        <Segment style={{ margin: 20 }}>
          <Header as="h1" style={{ margin: 20 }}>
            {multiRespuesta?.enunciado}
          </Header>
          <div style={{ marginLeft: 20 }}>
            <ReactMarkdown remarkPlugins={[gfm]} className="markDownMulti">
              {multiRespuesta?.documento?.descripcion}
            </ReactMarkdown>
            <div style={{ padding: 11 }}>
              <Form size="large">
                <Grid stackable columns='equal'>
                  <Grid.Column>
                    {multiRespuesta?.respuestaCampos?.map((input, key) => (
                      <InputsResponse key={key} input={input} num={key + 1} />
                    ))}
                  </Grid.Column>
                </Grid>
              </Form>
            </div>
          </div>
        </Segment>
      </Modal.Content>
    </>
  );
}



const DOCUMENT_RESPONSE = gql`
query responseDocument($idSolicitud: ID $idPago:ID) {
  multiRespuesta(idSolicitud: $idSolicitud idPago: $idPago) {
    id
    enunciado
    documento {
      id
      descripcion
    }
    respuestaCampos {
      id
      enunciadoCampo
      campo{
        id
        tipo{
          id
          valorParametro
        }
      }
      respuestaTexto
      urlArchivo
    }
  }
}`;

export default DocumentResponse;