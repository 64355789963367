import { gql, useQuery } from "@apollo/client";
import React, { useState } from "react";
import CustomCardList from "../../../Components/CustomCardList";
import { Card, Form, Label, Button, Message } from "semantic-ui-react";
import DownloadReports from "./Download";
const Reports = () => {
  const [documentType, setDocumentType] = useState("");
  const [documentName, setDocumentName] = useState({ value: "", fakeValue: "" });
  const variables = {};
  if (documentName.value.trim()) variables.nombre = documentName.value;
  if (documentType.trim()) variables.tipoDocumento = documentType;
  const { loading, error, data } = useQuery(GET_DOCUMENTS, { variables, fetchPolicy: "network-only" });

  const cleanFilter = () => {
    setDocumentType("");
    setDocumentName({ value: "", fakeValue: "" });
  }

  return (
    <CustomCardList
      loading={loading}
      error={error}
      data={data?.multiDocumentos}>
      {({ list }) => (
        <>
          <h1>Formularios</h1>
          <Form>
            <Form.Group inline>
              <Form.Field>
                <Label content={`Cantidad (${data?.multiDocumentos?.length || 0})`} />
              </Form.Field>
              <Form.Dropdown
                clearable
                label="Tipo de formulario"
                placeholder='Filtrar por tipo de formulario'
                value={documentType}
                onChange={(e, { value }) => setDocumentType(value)}
                options={[
                  { text: 'Pago', value: '8', key: '8' },
                  { text: 'Solicitud', value: '9', key: '9' }
                ]} />
              <Form.Input
                size="mini"
                value={documentName?.fakeValue}
                onChange={(e, { value }) => setDocumentName({ ...documentName, fakeValue: value })}
                action={<Button onClick={() => setDocumentName({ ...documentName, value: documentName.fakeValue })} size="mini" icon="search" content="Formulario" />}
                placeholder='Buscar por formulario' />
              <Form.Button
                icon="trash"
                onClick={cleanFilter}
                size="mini"
                content="Vaciar filtro"
              />
            </Form.Group>
          </Form>
          {list?.length <= 0 && (
            <Message>No se encontró ningún resultado.</Message>
          )}
          <Card.Group stackable>
            {list.map((document, key) => (
              <Card key={key}>
                <Card.Content>
                  <Card.Header>{document?.enunciado}</Card.Header>
                </Card.Content>
                <Card.Content extra>
                  <div className='ui two buttons'>
                    <DownloadReports
                      id={document?.id}
                      documentType={document?.tipoDocumento?.valorParametro}
                      fields={document?.campos}
                      name={document?.enunciado} />
                  </div>
                </Card.Content>
              </Card>
            ))}
          </Card.Group>
        </>
      )}
    </CustomCardList>
  );
}

const GET_DOCUMENTS = gql`
  query getDocuments($nombre: SearchString, $tipoDocumento: ID) {
    multiDocumentos(filtro: { estado: ACTIVO, nombre: $nombre, tipoDocumento: $tipoDocumento }) {
      id
      enunciado
      tipoDocumento {
        id
        valorParametro
      }
      campos {
        id
        enunciado
      }
    }
  }
`;

export default Reports;