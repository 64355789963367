import { gql, useMutation } from "@apollo/client";
import React, { useState } from "react";
import { Button, Form, Icon, Modal } from "semantic-ui-react";
import MDEditor from '@uiw/react-md-editor';
import Swal from "sweetalert2";
const CreateDocument = ({ trigger, loadingData, errorData, dataValue }) => {

  const [open, setOpen] = useState(false);
  const [name, setName] = useState("");
  const [enunciated, setEnunciated] = useState("");
  const [description, setDescription] = useState("");
  const [category, setCategory] = useState("");
  const [origin, setOrigin] = useState("");
  const [date, setDate] = useState("");
  const [finalDate, setFinalDate] = useState("");
  const [msjDate, setMsjDate] = useState("");
  const [msjFinalDate, setMsjFinalDate] = useState("");
  const [msjConfirmation, setMsjConfirmation] = useState("");
  const [msjRequestMailed, setMsjRequestMailed] = useState("");

  const [loading, setLoading] = useState(false);


  const onClose = () => {
    setOpen(false);
  }

  const disabled = () => {
    return (
      !name.trim() || !enunciated.trim() ||
      !description.trim() || !category.trim() ||
      !origin.trim() || !date.trim() ||
      !finalDate.trim() || !msjDate.trim() ||
      !msjFinalDate.trim() || !msjConfirmation.trim() ||
      !msjRequestMailed.trim()
    )
  }

  const onError = ({ graphQLErrors }) => {
    setLoading(false);
    if (graphQLErrors) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: graphQLErrors[0]?.message || "Lo sentimos, ocurrio un error inesperado."
      });
    } else {
      Swal.fire("Lo sentimos, ha ocurrido un error.");
    }
  };

  const onCompleted = () => {
    Swal.fire('El documento ha sido creado exitosamente.', '', 'success')
      .then(() => {
        setOpen(false);
      });
  }


  const [mutation] = useMutation(CREATE_DOCUMENT, { onCompleted, onError });
  const handleMutation = () => {
    setLoading(true);
    const input = {
      nombre: name,
      enunciado: enunciated,
      descripcion: description,
      idCategoria: category,
      fechaInicio: date,
      fechaCaducidad: finalDate,
      mensajeCaducidad: msjFinalDate,
      mensajeFechaInicio: msjDate,
      idTipoDocumento: "9",
      mensajeCorreoSolicitudEnviada: msjRequestMailed,
      mensajeConfirmacion: msjConfirmation
    }

    mutation({ variables: { input } });
  }

  return (
    <>
      {React.cloneElement(trigger, { onClick: () => setOpen(true) })}
      <Modal onClose={onClose} size="large" open={open}>
        <Modal.Header>
          Crear documento
          <Icon name="file" />
        </Modal.Header>
        <Modal.Content scrolling>
          <Form>
            <Form.Input
              label="Nombre"
              value={name}
              onChange={(e, { value }) => setName(value)} />
            <Form.Input
              label="Enunciado"
              value={enunciated}
              onChange={(e, { value }) => setEnunciated(value)} />
          </Form>
          <div style={{ marginTop: 10, marginBottom: 10 }}>
            <label style={{ fontWeight: "bold" }}>Descripción</label>
            <MDEditor
              value={description}
              onChange={setDescription}
            />
          </div>
          <Form>
            <Form.Dropdown
              selection
              clearable
              placeholder="Seleccione una categoría"
              loading={loadingData}
              error={errorData}
              label="Categorías"
              value={category}
              onChange={(e, { value }) => setCategory(value)}
              options={dataValue?.categoria || []} />
            <Form.Dropdown
              selection
              clearable
              placeholder="Seleccione una procedencia"
              loading={loadingData}
              error={errorData}
              label="Procedencias"
              value={origin}
              onChange={(e, { value }) => setOrigin(value)}
              options={dataValue?.procedencia || []} />
            <Form.Input
              label="Fecha Inicio"
              value={date}
              onChange={(e, { value }) => setDate(value)}
              type="date" />
            <Form.TextArea
              label="Mensaje fecha inicio"
              value={msjDate}
              onChange={(e, { value }) => setMsjDate(value)} />
            <Form.Input
              label="Fecha de caducidad"
              value={finalDate}
              onChange={(e, { value }) => setFinalDate(value)}
              type="date" />
            <Form.TextArea
              value={msjFinalDate}
              onChange={(e, { value }) => setMsjFinalDate(value)}
              label="Mensaje de caducidad" />
            <Form.TextArea
              value={msjConfirmation}
              onChange={(e, { value }) => setMsjConfirmation(value)}
              label="Mensaje de confirmación" />
            <Form.TextArea
              value={msjRequestMailed}
              onChange={(e, { value }) => setMsjRequestMailed(value)}
              label="Mensaje de solicitud por correo" />
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button loading={loading} onClick={handleMutation} disabled={disabled() || loading} secondary content="Crear" />
          <Button onClick={onClose} content="Cancelar" />
        </Modal.Actions>
      </Modal>
    </>
  )
}


const CREATE_DOCUMENT = gql`
mutation createDocumentRequest($input: CrearMultiDocumentoInput!) {
  crearMultiDocumento(input: $input) {
    id
    nombre
  }
}`;

export default CreateDocument;

