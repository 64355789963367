import { gql, useMutation, useQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { Card, Container, Form, Button, Icon, Label, Message, Divider } from "semantic-ui-react";
import Swal from "sweetalert2";
import CustomCardList from "../../../../Components/CustomCardList";
import CreateDocument from "./Document/CreateDocument";

const Categorie = ({ match: { params: { id } }, history }) => {
  const { loading, error, data, refetch } = useQuery(CATEGORIE, { variables: { id }, fetchPolicy: "network-only" });
  return (
    <Container text>
      <h1><Icon onClick={() => history.replace("/dashboard/Categorías")} link name="arrow left" /> Categoría</h1>
      {data?.multiCategoria ? (
        <Content
          idCategory={id}
          refetch={refetch}
          history={history}
          loading={loading}
          error={error}
          categorie={data?.multiCategoria} />
      ) : <Message>La categoría que intentas acceder no existe.</Message>}
    </Container>
  );
}

const Content = ({ categorie, loading, error, idCategory, history, refetch }) => {
  const [name, setName] = useState("");
  const [order, setOrder] = useState("");
  const [mutationLoading, setMutationLoading] = useState(false);


  const onCompleted = () => {
    setMutationLoading(false);
  }

  const onError = ({ graphQLErrors }) => {
    setMutationLoading(false);
    if (graphQLErrors.length > 0) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: graphQLErrors[0]?.message
      });
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: "Lo sentimos, ha ocurrido un error."
      });
    }
  };

  const goToDocument = id => {
    history.push("/dashboard/Categoría/" + idCategory + "/Documento/" + id);
  }

  const [mutation] = useMutation(UPDATE_CATEGORIE, {
    onCompleted,
    onError
  });

  const handleMutation = () => {
    setMutationLoading(true);
    mutation({
      variables: {
        id: categorie?.id,
        nombre: name,
        orden: parseInt(order)
      }
    });
  }
  useEffect(() => {
    setName(categorie?.nombre || "");
    setOrder(categorie?.orden || 0)
  }, [categorie?.nombre, categorie?.orden])

  return (
    <>
      <Form>
        <Form.Input
          value={name || ""}
          onChange={(e, { value }) => setName(value)}
          label="Nombre" />
        <Form.Input
          min="0"
          value={order}
          type="number"
          onChange={(e, { value }) => setOrder(value)}
          label="Orden" />
        <Form.Button
          loading={mutationLoading}
          style={{ backgroundColor: "#045A73", color: "white" }}
          disabled={!name || (typeof name === "string" && !name.trim()) || Number.isNaN(parseInt(order)) || parseInt(order) < 0 || loading}
          onClick={handleMutation}
          content="Guardar" />
      </Form>

      <Divider horizontal>Documentos</Divider>
      <br />
      <br />
      <CustomCardList
        loading={loading}
        error={error}
        data={categorie?.documentos}>
        {({ list }) => (
          <Card.Group stackable>
            {list.map((document, key) => (
              <Card key={key}>
                <Card.Content>
                  <Label style={{ backgroundColor: document?.estado === "ACTIVO" ? "#045A73" : null, color: document?.estado === "ACTIVO" ? "white" : null }} attached='top left'>{document?.estado}</Label>
                  <Card.Header>{document?.enunciado}</Card.Header>
                  <Card.Meta>{document?.tipoDocumento?.valorParametro}</Card.Meta>
                  <Card.Description>
                    Procedencias: <strong>{document?.procedencias?.map(value => value?.procedencia?.valorParametro)?.join(' , ')}</strong>
                  </Card.Description>
                </Card.Content>
                <Card.Content extra>
                  <div className='ui two buttons'>
                    <Button
                      style={{ backgroundColor: "#045A73", color: "white" }}
                      onClick={() => goToDocument(document?.id)}
                      icon="arrow alternate circle right" />
                  </div>
                </Card.Content>
              </Card>
            ))}
            <CreateDocument history={history} refetch={refetch} category={categorie?.id} />
          </Card.Group>
        )}
      </CustomCardList>
    </>
  );
}

const CATEGORIE = gql`
  query getCategorie($id: ID) {
    multiCategoria(id: $id) {
      id
      nombre
      orden
      documentos {
        id
        nombre
        enunciado
        estado
        procedencias {
          id
          procedencia {
            id
            valorParametro
          }
        }
        tipoDocumento {
          id
          valorParametro
        }
      }
    }
  }
`;

const UPDATE_CATEGORIE = gql`
  mutation updateCategorie($id: ID!, $nombre: SearchString, $orden: Int) {
    actualizarMultiCategoria(id: $id, nombre: $nombre, orden: $orden) {
      id
      nombre
      orden
    }
  }
`;

export default Categorie;