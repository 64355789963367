import { gql, useMutation } from "@apollo/client";
import React, { useState } from "react";
import { Button, Form, Popup } from "semantic-ui-react";
import Swal from "sweetalert2";


const Content = ({ module, reload }) => {
  const [name, setName] = useState(module?.nombre || "");
  const [status, setStatus] = useState(module?.estado || "");

  const onCompleted = async () => {
    await reload();
    Swal.fire({
      icon: 'success',
      title: 'Completado',
      text: 'Se ha actualizado el módulo de manera exitosa.'
    })
  };


  const onError = ({ graphQLErrors }) => {
    if (graphQLErrors) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: graphQLErrors[0]?.message
      });
    } else {
      Swal.fire("Lo sentimos, ha ocurrido un error.");
    }
  };

  const [mutation] = useMutation(EDIT_MODULE, { onCompleted, onError });
  const handleMutation = () => {
    mutation({
      variables: {
        id: module.id,
        nombre: name,
        estado: status
      }
    })
  };
  return (
    <Form>
      <Form.Input
        value={name}
        onChange={(e, { value }) => setName(value)}
        placeholder="Nombre" />
      <Form.Dropdown
        selection
        value={status}
        onChange={(e, { value }) => setStatus(value)}
        options={options} />
      <Button onClick={handleMutation} disabled={!name.trim() || !status.trim()}>Guardar</Button>
    </Form>
  )
};

const options = [
  { text: "Activo", value: "ACTIVO", key: "ACTIVO" },
  { text: "Inactivo", value: "INACTIVO", key: "INACTIVO" },
  { text: "Eliminado", value: "ELIMINADO", key: "ELIMINADO" },
];

const UpdateModule = ({ module, reload }) => (
  <Popup
    on='click'
    trigger={<Button content='Editar' icon='edit' />}
    wide>
    <Content module={module} reload={reload} />
  </Popup>
);


const EDIT_MODULE = gql`
  mutation actualizarModulo($id: ID!, $nombre: String!, $estado: Status) {
    actualizarMultiModulo(id: $id, nombre: $nombre, estado: $estado) {
      id
      nombre
      estado
    }
  }
`;

export default UpdateModule;