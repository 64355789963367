import React, { useState } from "react";
import { useQuery, gql } from "@apollo/client";
import CustomTable from "../../../Components/CustomTable";
import CreateModule from "./Create";
import UpdateModule from "./Update";
import DeleteModule from "./Delete";
import { Form } from "semantic-ui-react";

const options = [
  { text: "Activo", value: "ACTIVO", key: "ACTIVO" },
  { text: "Inactivo", value: "INACTIVO", key: "INACTIVO" },
  { text: "Eliminado", value: "ELIMINADO", key: "ELIMINADO" },
];

const Modules = ({ permissions }) => {
  const [status, setStatus] = useState("ACTIVO");
  const [name, setName] = useState("");
  const [fakeName, setFakeName] = useState("");
  const { data, loading, error, refetch } = useQuery(
    GET_MODULES,
    {
      variables: { filtro: { estado: status, nombre: name } },
      fetchPolicy: "network-only"
    }
  );

  const CREATE = permissions?.some(value => value?.permiso.nombre === "Crear");
  const EDIT = permissions?.some(value => value?.permiso.nombre === "Editar");
  const DELETE = permissions?.some(value => value?.permiso?.nombre === "Eliminar");


  const columns = [
    {
      header: 'Id',
      accessor: 'id'
    },
    {
      header: 'Nombre',
      accessor: 'nombre'
    },
    {
      header: 'Estado',
      accessor: 'estado'
    }
  ];

  if (EDIT) columns.push(
    {
      header: 'Acción de editar',
      render: data => <UpdateModule reload={refetch} module={data} />
    }
  );

  if (DELETE && status !== "ELIMINADO") columns.push(
    {
      header: 'Acción de eliminar',
      render: data => <DeleteModule reload={refetch} id={data.id} />
    }
  );


  return (
    <div>
      <h1>Módulos</h1>
      <Form>
        <Form.Group inline>
          {CREATE && <CreateModule reload={refetch} />}
          <Form.Dropdown
            selection
            value={status}
            onChange={(e, { value }) => setStatus(value)}
            options={options} />
          <Form.Input
            action={{
              icon: 'search',
              content: 'Buscar',
              onClick: () => setName(fakeName)
            }}
            value={fakeName}
            onChange={(e, { value }) => setFakeName(value)}
            placeholder="Búsqueda por nombre" />
        </Form.Group>
      </Form>
      <CustomTable
        data={data?.multiModulos}
        loading={loading}
        error={error}
        columns={columns}
      />
    </div>
  );
};

const GET_MODULES = gql`
  query getModules($filtro: MultiModuloFiltro!) {
    multiModulos(filtro: $filtro) {
      id
      nombre
      estado
    }
  }
`;

export default Modules;