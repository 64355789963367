import { gql, useMutation } from "@apollo/client";
import React, { useState } from "react";
import { Divider, Form, Label, Segment } from "semantic-ui-react";
import Swal from "sweetalert2";
import Options from "./Options";

const TypeInputs = ({ input, refetch, limitAforo }) => {
  const id = input?.id;
  const type = input?.tipo?.valorParametro;
  const options = input?.opciones || [];
  const [enunciated, setEnunciated] = useState(input?.enunciado || "");
  const [name, setName] = useState(input?.nombre || "");
  const [required, setRequired] = useState(input?.obligatorio || false);
  const [loading, setLoading] = useState(false);

  const onCompleted = async () => {
    setLoading(false);
    await refetch();
  };

  const onError = ({ graphQLErrors }) => {
    setLoading(false);
    if (graphQLErrors?.length > 0) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: graphQLErrors[0]?.message,
      });
    } else {
      Swal.fire("Lo sentimos, ha ocurrido un error.");
    }
  };

  const [mutation] = useMutation(UPDATE_DOCUMENT_FIELD, {
    onCompleted,
    onError,
  });

  const handleMutation = () => {
    setLoading(true);
    const values = {
      id,
      nombre: name,
      enunciado: enunciated,
      obligatorio: required,
      estado: "ACTIVO",
    };

    mutation({ variables: { input: values } });
  };

  const handleDelete = () => {
    setLoading(true);

    Swal.fire({
      icon: "warning",
      text: "¿Está seguro de querer eliminar este campo?",
      showCancelButton: true,
      showConfirmButton: true,
      confirmButtonText: "Confirmar",
      cancelButtonText: "Cancelar",
    }).then((e) => {
      if (e.isConfirmed) {
        mutation({ variables: { input: { id, estado: "ELIMINADO" } } });
        Swal.fire({
          icon: "success",
          text: "Realizado correctamente",
          timer: 1500
        })
      }
    });
  };

  return (
    <Segment style={{ marginTop: 20 }}>
      <Label
        style={{ backgroundColor: "#045A73", color: "white" }}
        attached="top left"
      >
        {type}
      </Label>
      <Form>
        <Form.Group widths="2">
          <Form.TextArea
            label="Nombre"
            style={{ maxHeight: 50, minHeight: 50 }}
            value={name}
            onChange={(e, { value }) => setName(value)}
            placeholder="Agregar Nombre"
          />
          <Form.TextArea
            label="Enunciado"
            style={{ maxHeight: 50, minHeight: 50 }}
            value={enunciated}
            onChange={(e, { value }) => setEnunciated(value)}
            placeholder="Agregar Enunciado"
          />
        </Form.Group>
        <Form.Group widths="equal">
          {
            (type === "Opcion - Aforo") && (
              <Form.Input
                label="Aforos"
                value={limitAforo}
                disabled={true}
              />
            )
          }
          <Form.Checkbox
            checked={required}
            onChange={(e, { checked }) => setRequired(checked)}
            toggle
            label="Obligatorio"
            style={{ marginTop: type === "Opcion - Aforo" ? 30 : 0, marginLeft: type === "Opcion - Aforo" ? 50 : 30 }}
          />
          {/* <Form.Button color="red" icon="delete" />*/}
        </Form.Group>
        <Form.Group>
          <Form.Button
            loading={loading}
            disabled={!enunciated.trim() || !name.trim() || loading}
            onClick={handleMutation}
            style={{ backgroundColor: "#045A73", color: "white" }}
            content="Guardar"
          />
          <Form.Button
            loading={loading}
            disabled={loading}
            onClick={handleDelete}
            style={{ backgroundColor: "#045A73", color: "white" }}
            content="Eliminar"
          />
        </Form.Group>
      </Form>

      {(type === "Opcion" || type === "Radio") && (
        <>
          <Divider horizontal>Opciones</Divider>
          <Options refetch={refetch} options={options} idCampo={id} />
        </>
      )}

      {(type === "Opcion - Aforo") && (
        <>
          <Divider horizontal>Opciones</Divider>
          <Options refetch={refetch} options={options} idCampo={id} aforo={type === "Opcion - Aforo"} limit={limitAforo}/>
        </>
      )}
    </Segment>
  );
};

const UPDATE_DOCUMENT_FIELD = gql`
  mutation updateDocumentField($input: ActualizarMultiCampoInput!) {
    actualizarMultiCampo(input: $input) {
      id
      nombre
      obligatorio
      tipo {
        id
        valorParametro
      }
      enunciado
      estado
    }
  }
`;

export default TypeInputs;
