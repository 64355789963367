import React from "react";
import { Form, Button } from "semantic-ui-react";
import XLSX from "xlsx";

const CustomXls = ({
  content = "Descargar",
  data = [],
  columns = [],
  fileName,
  floated,
}) => {
  const handleClick = () => {
    const travelObj = (obj = null, path, anyData = false) => {
      if (!path) return obj;
      const pathObj = path.split(".");
      let value = pathObj.reduce((cursor, pathPart) => cursor[pathPart], obj);
      if (anyData) return value;

      return typeof value !== "object" || value !== "function" ? value : "";
    };
    const responseData = [];
    const formatFields = Array.isArray(columns) && columns.length > 0;
    if (formatFields) {
      data.forEach(value => {
        const obj = {}
        columns.forEach(column => {
          obj[column.header] = column.render ? column.render(travelObj(value, column.accessor, true)) : travelObj(value, column.accessor)
        })
        responseData.push(obj);
      });
    };

    const _data = XLSX.utils.json_to_sheet(formatFields ? responseData : data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, _data, fileName);
    XLSX.writeFile(wb, `${fileName}.xlsx`);
  };
  return (
    <Form.Field
      compact
      icon="file excel"
      floated={floated}
      disabled={!data.length}
      onClick={handleClick}
      size="mini"
      control={Button}
      content={content}
    />
  );
};

export default CustomXls;