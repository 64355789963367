import { gql, useQuery } from "@apollo/client";
import React from "react";
import { Form } from "semantic-ui-react";

const AgentFilter = ({ value, onChange }) => {
  const { loading, error, data } = useQuery(GET_AGENT);

  return (
    <Form.Dropdown
      search
      scrolling
      loading={loading}
      error={error}
      value={value}
      onChange={onChange}
      label="Agente SaC"
      placeholder="Búsqueda por agente"
      options={data?.multiUsuarios?.items || []} />
  );
}
const GET_AGENT = gql`
  query getAgent {
    multiUsuarios(filtro: { estado: ACTIVO, idGrupoUsuario: 3 }, pagina: {limit:100, number:1}) {
      items {
        value: id
        text: nombre
        key: id
      }
    }
  }
`;

export default AgentFilter;