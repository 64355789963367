import { gql, useMutation } from "@apollo/client";
import React, { useState } from "react";
import { Form } from "semantic-ui-react";
import Swal from "sweetalert2";

const PublishDocument = ({ id, status }) => {
  const [loading, setLoading] = useState(false);

  const onError = ({ graphQLErrors }) => {
    setLoading(false);
    if (graphQLErrors?.length > 0) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: graphQLErrors[0]?.message
      });
    } else {
      Swal.fire("Lo sentimos, ha ocurrido un error.");
    }
  };

  const onCompleted = ({ actualizarMultiDocumento }) => {
    setLoading(false);
    const res = actualizarMultiDocumento?.estado === "ACTIVO" ? "Publicado" : "Inactivado";
    Swal.fire(`El documento fue ${res} exitosamente. `, '', 'success');
  }

  const [mutation] = useMutation(UPDATE_DOCUMENT_STATUS, { onCompleted, onError });

  const handleMutation = () => {
    Swal.fire({
      title: '¿Deseas realizar está acción?',
      showDenyButton: true,
      confirmButtonText: 'Aceptar',
      denyButtonText: 'Cancelar',
    }).then((result) => {
      if (result.isConfirmed) {
        setLoading(true);
        mutation({
          variables: {
            input: {
              id,
              estado: status === "ACTIVO" ? "INACTIVO" : "ACTIVO"
            }
          }
        });
      }
    });
  }

  if (status === "ACTIVO") return (
    <Form.Group widths="equal">
      <Form.Button
        loading={loading}
        onClick={handleMutation}
        error content="Inactivar" />
    </Form.Group>
  );


  return (
    <Form.Group widths="equal">
      <Form.Button
        style={{ backgroundColor: "#045A73", color: "white" }}
        onClick={handleMutation}
        loading={loading}
        content="Publicar" />
    </Form.Group>
  );
}

const UPDATE_DOCUMENT_STATUS = gql`
  mutation updateDocument($input: ActializarMultiDocumentoInput!) {
    actualizarMultiDocumento(input: $input) {
      id
      estado
    }
  }
`;

export default PublishDocument;