import { gql, useMutation } from "@apollo/client";
import React, { useState } from "react";
import { Button, Form, Popup } from "semantic-ui-react";
import Swal from "sweetalert2";

const CreateObservation = ({ id }) => {
  const [observation, setObservation] = useState("");
  const [loading, setLoading] = useState(false);


  const onError = ({ graphQLErrors }) => {
    setLoading(false);
    if (graphQLErrors?.length > 0) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: graphQLErrors[0]?.message
      });
    } else {
      Swal.fire("Lo sentimos, ha ocurrido un error.");
    }
  };

  const onCompleted = () => {
    setLoading(false);
    setObservation("");
    Swal.fire(`Observación agregada. `, '', 'success');
  }

  const [mutation] = useMutation(CREATE_OBSERVATION, { onCompleted, onError });

  const handleMutation = () => {
    setLoading(true);
    mutation({
      variables: {
        id,
        observacion: observation
      }
    })
  }

  return (
    <Popup
      content={
        <Form>
          <Form.TextArea
            onChange={(e, { value }) => setObservation(value)}
            label='Observación' />
          <Form.Button
            disabled={!observation.trim() || loading}
            style={{ backgroundColor: '#045A73', color: 'white' }}
            onClick={handleMutation}
            content='Guardar' />
        </Form>
      }
      on='click'
      trigger={
        <Button
          size='mini'
          style={{ backgroundColor: '#045A73', color: 'white' }}
          icon='add'
        />
      }
    />
  )
}

const CREATE_OBSERVATION = gql`
  mutation createObservation($id: ID!, $observacion: String!) {
    crearObservacionPago(id: $id, observacion: $observacion) {
      id
      observacion
    }
  }
`;

export default CreateObservation;