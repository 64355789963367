import { gql, useQuery } from "@apollo/client";
import React, { useState } from "react";
import { Message, Popup, Button } from "semantic-ui-react";
import PlaceholderMultiLine from "../../../Components/PlaceholderMultiLine";
import CustomXls from "../../../Components/CustomXlsx";
const host = process.env.NODE_ENV === 'production' ? "https://solicitudes.multidiomasltda.com:3000" : "http://localhost:4000";
const Content = ({ id, documentType, name, fields }) => {
  const { loading, error, data } = useQuery(DOCUMENT_RESPONSES, { variables: { idDocumento: id } });

  if (loading) return <PlaceholderMultiLine style={{ minWidth: 200 }} numberLine={5} />;

  if (error) return <Message>Los sentimos, ha ocurrido un error.</Message>

  const columns = [
    {
      header: 'Nombre',
      accessor: 'usuario.nombre'
    },
    {
      header: 'No. documento',
      accessor: 'usuario.documento'
    },
    {
      header: 'Tipo de documento',
      accessor: 'usuario.tipoDocumento.valorParametro'
    },
    {
      header: 'Lugar documento expedición',
      accessor: 'usuario.ciudadDocumentoExpedicion.nombre'
    },
    {
      header: 'Correo',
      accessor: 'usuario.email'
    },
    {
      header: 'Celular',
      accessor: 'usuario.telefono'
    },
    {
      header: 'Procedencia',
      accessor: 'usuario.valorProcedencia.valorParametro'
    },
  ];

  if(documentType === "Solicitud") columns.push({
    header: 'Estado solicitud',
    accessor: 'solicitud.estadoSolicitud.valorParametro'
  });

  if(documentType === "Pago"){
    columns.push(
      {
        header: 'Referencia',
        accessor: 'pago.referenciaPago'
      },
      {
        header: 'Estado pago',
        accessor: 'pago.estadoPago'
      },
      {
        header: 'Valor cancelado',
        accessor: 'pago.valor'
      },
      {
        header: 'Método de pago',
        accessor: 'pago.metodoPago'
      },
    );
  }

  const { multiRespuestas } = data;

  if (fields?.length > 0) {
    fields.forEach((v, key) => {
      columns.push({
        header: v?.enunciado,
        accessor: 'respuestaCampos',
        render: response => (response[key]?.respuestaTexto) || (response[key]?.urlArchivo ? host + response[key]?.urlArchivo : null)
      });
    });
  }

  return (
    <CustomXls
      columns={columns}
      fileName={name.substr(0,31)}
      data={multiRespuestas || []} />
  );
}

const DownloadReports = ({ id, name, fields, documentType }) => {
  const [open, setOpen] = useState(false);
  return (
    <Popup
      on='click'
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      trigger={<Button content='Generar reporte' icon='line graph' />}
      wide
    >
      {open && <Content id={id} documentType={documentType} name={name} fields={fields} />}
    </Popup>
  );
}

const DOCUMENT_RESPONSES = gql`
  query documentResponses($idDocumento: ID!) {
    multiRespuestas(filtro: { idDocumento: $idDocumento, estado: ACTIVO }) {
      id
      usuario {
        id
        nombre
        telefono
        documento
        email
        valorProcedencia {
         id
         valorParametro
        }
        tipoDocumento {
          id
          valorParametro
        }
        ciudadDocumentoExpedicion {
          id
          nombre
        }
      }
      solicitud {
        id
        estadoSolicitud {
          id
          valorParametro
        }
      }
      pago {
        id
        estadoPago
        referenciaPago
        valor
        metodoPago
      }
      respuestaCampos {
        id
        enunciadoCampo
        respuestaTexto
        urlArchivo
      }
    }
  }
`;

export default DownloadReports;