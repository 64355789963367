import { gql, useQuery } from "@apollo/client";
import moment from "moment";
import React, { useState } from "react";
import { Form, Icon, Label, Popup } from "semantic-ui-react";
import { uri } from "../../../client";
import CustomTable from "../../../Components/CustomTablePagination";
import CustomXls from "../../../Components/CustomXlsx";
import { parseGender } from "../../../Utils";
import UpdateEmail from "./UpdateEmail";
import Verify from "./Verify";
const Multiculturalidades = ({ data }) =>
  data?.map((v, key) => (
    <p key={key}>
      {key + 1}. {v?.valorParametro}
    </p>
  ));
const FechaAceptacionTerminosYCondiciones = ({ data }) => (
  <p>
    Fecha aceptación: {moment(parseInt(data?.fecha_creacion)).format("DD/MM/YYYY h:mm:ss a")} <br />
  </p>
);
const FechaAceptacionTerminosYCondicionesNuevos = ({ data }) => (
  <p>
    Fecha aceptación: {moment(parseInt(data)).format("DD/MM/YYYY h:mm:ss a")} <br />
  </p>
);
const Students = ({ permissions }) => {
  const EDIT = permissions?.some((value) => value?.permiso.nombre === "Editar");
  const [status, setStatus] = useState("ACTIVO");
  const [name, setName] = useState("");
  const [form, setForm] = useState("");
  const [origin, setOrigin] = useState("");
  const [email, setEmail] = useState("");
  const [loadingRefetch, setLoadingRefetch] = useState(false);
  const [fakeName, setFakeName] = useState("");
  const [fakeEmail, setFakeEmail] = useState("");
  const [document, setDocument] = useState({ value: "", fakeValue: "" });
  const [currentPage, setCurrentPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(10);

  const filtro = { estado: status, idGrupoUsuario: "2" };

  if (name.trim()) filtro.nombre = name;
  if (email.trim()) filtro.email = email;
  if (form) filtro.idFormulario = form;
  if (origin) filtro.idProcedencia = origin;
  if (document?.value.trim()) filtro.documento = document?.value;
  const { loading, error, data, refetch } = useQuery(STUDENTS, {
    variables: { filtro, pagina: { number: currentPage, limit: pageLimit } },
    fetchPolicy: "network-only",
  });

  const columns = [
    {
      header: "Foto",
      accessor: "url_imagen",
      render: (url_imagen) => (
        <>
          {url_imagen !== null ? (
            <img
              src={uri + url_imagen}
              alt="foto"
              width="100"
              height="100"
              style={{ borderRadius: "50%" }}
            />
          ) : (
            <Icon style={{ color: "#B0193F" }} name="user" size="huge" />
          )}
        </>
      ),
    },
    {
      header: "Primer nombre",
      accessor: "primerNombre",
    },
    {
      header: "Segundo nombre",
      accessor: "segundoNombre",
    },
    {
      header: "Primer apellido",
      accessor: "primerApellido",
    },
    {
      header: "Segundo Apellido",
      accessor: "segundoApellido",
    },
    {
      header: "Documento",
      accessor: "documento",
    },
    {
      header: "Correo",
      accessor: "email",
    },
    {
      header: "Celular",
      accessor: "telefono",
    },
    {
      header: "Género",
      accessor: "genero",
      render: (value) => parseGender(value),
    },
    {
      header: "Teléfono fijo",
      accessor: "telefono",
    },
    {
      header: "Procedencia",
      accessor: "valorProcedencia.valorParametro",
    },
    {
      header: "Dirección",
      accessor: "direccion",
    },
    {
      header: "Ciudad",
      accessor: "ciudad.nombre",
    },
    {
      header: "Tipo documento",
      accessor: "tipoDocumento.valorParametro",
    },
    {
      header: "Fecha expedición documento",
      accessor: "fechaDocumentoExpedicion",
      render: (value) => moment(value).format("DD/MM/YYYY"),
    },
    {
      header: "Ciudad expedición documento",
      accessor: "ciudadDocumentoExpedicion.nombre",
    },
    {
      header: "Estado civil",
      accessor: "estadoCivil.valorParametro",
    },
    {
      header: "Fecha de nacimiento",
      accessor: "fechaNacimiento",
      render: (value) => moment(value).format("DD/MM/YYYY"),
    },
    {
      header: "Estrato",
      accessor: "estrato.valorParametro",
    },
    {
      header: "Regimen de salud",
      accessor: "regimenSalud.valorParametro",
    },
    {
      header: "Nivel de formación",
      accessor: "nivelFormacion.valorParametro",
    },
    {
      header: "Ocupación",
      accessor: "ocupacion.valorParametro",
    },
    {
      header: "Discapacidad",
      accessor: "discapacidad.valorParametro",
    },
    {
      header: "Fecha de autorización de la política de uso y tratamiento de datos personales.",
      accessor: "fechaAceptacionTerminos",
      render: (fechaAceptacionTerminos) => {
        return fechaAceptacionTerminos ? (
          <Popup
            on="click"
            pinned
            content={<FechaAceptacionTerminosYCondiciones data={fechaAceptacionTerminos} />}
            trigger={<Label as="a" content="Click para ver" />}
          />
        ) : (
          <>El usuario no tiene fecha de términos y condiciones</>
        );
      },
    },
    {
      header: "Fecha de autorización de la nueva política de protección de datos.",
      accessor: "fechaTerminosYCondicionesNuevos",
      render: (fechaTerminosYCondicionesNuevos) => {
        return fechaTerminosYCondicionesNuevos ? (
          <Popup
            on="click"
            pinned
            content={<FechaAceptacionTerminosYCondicionesNuevos data={fechaTerminosYCondicionesNuevos} />}
            trigger={<Label as="a" content="Click para ver" />}
          />
        ) : (
          <>El usuario no tiene fecha de términos y condiciones</>
        );
      },
    },
    {
      header: "Multiculturalidades",
      accessor: "multicularidades",
      render: (multiculturalidades) => {
        return (
          <Popup
            on="click"
            pinned
            content={<Multiculturalidades data={multiculturalidades} />}
            trigger={<Label as="a" content="Click para ver" />}
          />
        );
      },
    },
  ];

  if (status === "INACTIVO")
    columns.push({
      header: "Reenviar correo",
      accessor: "email",
      render: (v) => <Verify email={v} />,
    });

  if (EDIT)
    columns.push({
      header: "Editar correo",
      accessor: "id",
      render: (id) => <UpdateEmail id={id} />,
    });

  const columnsXls = [...columns];
  const indexMulticulturalidades = columnsXls.findIndex(
    (v) => v.header === "Multiculturalidades"
  );
  if (indexMulticulturalidades !== -1)
    columnsXls.push({
      header: "Multiculturalidades",
      accessor: "multicularidades",
      render: (array) => array?.map((v) => v.valorParametro)?.join(","),
    });

  const cleanFilter = () => {
    setName("");
    setEmail("");
    setFakeEmail("");
    setFakeName("");
    setForm("");
    setOrigin("");
    setDocument({ value: "", fakeValue: "" });
  };

  const handleRefetch = async () => {
    setLoadingRefetch(true);
    await refetch();
    setLoadingRefetch(false);
  };

  return (
    <div>
      <h1>Estudiantes</h1>
      <Form>
        <Form.Group inline>
          <Form.Field>
            <Label content={`Cantidad (${data?.multiUsuarios?.count || 0})`} />
          </Form.Field>
          <Form.Dropdown
            value={status}
            onChange={(e, { value }) => setStatus(value)}
            label="Estado"
            options={[
              { text: "Activo", value: "ACTIVO", key: 1 },
              { text: "Inactivo", value: "INACTIVO", key: 2 },
            ]}
          />
          <Form.Dropdown
            search
            scrolling
            value={form}
            onChange={(e, { value }) => setForm(value)}
            label="Formulario"
            placeholder="Búsqueda por formulario"
            options={data?.multiDocumentos || []}
          />
          <Form.Dropdown
            clearable
            placeholder="Filtro de procedencia"
            loading={loading}
            error={error}
            label="Procedencia"
            value={origin}
            onChange={(e, { value }) => setOrigin(value)}
            options={data?.procedencia || []}
          />
        </Form.Group>
        <Form.Group inline>
          <Form.Input
            size="mini"
            action={{
              size: "mini",
              icon: "search",
              content: "Buscar",
              onClick: () => setName(fakeName),
            }}
            value={fakeName}
            onChange={(e, { value }) => setFakeName(value)}
            placeholder="Búsqueda por nombre"
          />
          <Form.Input
            size="mini"
            action={{
              size: "mini",
              icon: "search",
              content: "Buscar",
              onClick: () =>
                setDocument({ ...document, value: document.fakeValue }),
            }}
            value={document?.fakeValue}
            onChange={(e, { value }) =>
              setDocument({ ...document, fakeValue: value })
            }
            placeholder="No.documento"
          />
          <Form.Input
            size="mini"
            action={{
              icon: "search",
              size: "mini",
              content: "Buscar",
              onClick: () => setEmail(fakeEmail),
            }}
            value={fakeEmail}
            onChange={(e, { value }) => setFakeEmail(value)}
            placeholder="Búsqueda por email"
          />
          <CustomXls
            columns={columnsXls}
            fileName="Estudiantes"
            data={data?.multiUsuarios?.items || []}
          />
          <Form.Button
            icon="undo"
            content="Actualizar"
            loading={loadingRefetch}
            size="mini"
            onClick={handleRefetch}
          />
          <Form.Button
            icon="trash"
            size="mini"
            content="Vaciar filtros"
            onClick={cleanFilter}
          />
        </Form.Group>
      </Form>
      <CustomTable
        data={data?.multiUsuarios?.items || []}
        loading={loading || loadingRefetch}
        error={error}
        columns={columns}
        currentPage={currentPage}
        onPageChange={(e, { activePage }) => setCurrentPage(activePage)}
        pageLimit={pageLimit}
        setPageLimit={setPageLimit}
        setCurrentPage={setCurrentPage}
        totalPages={data?.multiUsuarios?.totalPages || 0}
      />
    </div>
  );
};

const STUDENTS = gql`
  query userStudents($filtro: MultiUsuarioFiltro!, $pagina: PageInput!) {
    multiDocumentos(filtro: { estado: ACTIVO }) {
      value: id
      text: nombre
      key: id
    }
    procedencia: multiValorParametros(
      filtro: { estado: ACTIVO, idParametro: 1 }
    ) {
      value: id
      text: valorParametro
      key: id
    }
    multiUsuarios(filtro: $filtro, pagina: $pagina) {
      page {
        number
        limit
      }
      totalPages
      count
      items {
        id
        nombre
        primerNombre
        segundoNombre
        primerApellido
        segundoApellido
        documento
        email
        telefono
        genero
        fechaTerminosYCondicionesNuevos
        url_imagen
        valorProcedencia {
          id
          valorParametro
        }
        direccion
        tipoDocumento {
          id
          valorParametro
        }
        estadoCivil {
          id
          valorParametro
        }
        fechaNacimiento
        estrato {
          id
          valorParametro
        }
        regimenSalud {
          id
          valorParametro
        }
        nivelFormacion {
          id
          valorParametro
        }
        ocupacion {
          id
          valorParametro
        }
        discapacidad {
          id
          valorParametro
        }
        fechaDocumentoExpedicion
        ciudad {
          id
          nombre
        }
        ciudadDocumentoExpedicion {
          id
          nombre
        }
        multicularidades {
          id
          valorParametro
        }
        estado
        fechaAceptacionTerminos {
          fecha_creacion
          fecha_expiracion
          tipo_token
          fecha_usado
          token_usado
        }
      }
    }
  }
`;

export default Students;
