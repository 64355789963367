import React, { useState } from "react";
import { Modal, Dropdown, Card, Button, Form, Segment, Divider } from "semantic-ui-react";
import MDEditor from '@uiw/react-md-editor';
import { gql, useMutation, useQuery } from "@apollo/client";
import Swal from "sweetalert2";
import moment from "moment";
const options = [
  {
    text: "Solicitud",
    value: "9",
    key: "Solicitud"
  },
  {
    text: "Pago",
    value: "8",
    key: "Pago"
  }
];

const CreateDocument = ({ category, history, refetch }) => {
  const [open, setOpen] = useState(false);
  const [loadingMutation, setLoadingMutation] = useState(false);
  const [documentType, setDocumentType] = useState("");
  const [name, setName] = useState("");
  const [enunciated, setEnunciated] = useState("");
  const [description, setDescription] = useState("");
  const [origin, setOrigin] = useState([]);
  const [date, setDate] = useState("");
  const [finalDate, setFinalDate] = useState("");
  const [msjDate, setMsjDate] = useState("");
  const [msjFinalDate, setMsjFinalDate] = useState("");
  const [msjOpenValue, setMsjOpenValue] = useState("");
  const [msjConfirmation, setMsjConfirmation] = useState("");
  const [msjRequestMailed, setMsjRequestMailed] = useState("");
  const [amount, setAmount] = useState(0);
  const [studentLimit, setStudentLimit] = useState(0);
  const [typeDocumentPayment, setTypeDocumentPayment] = useState("");

  const disabledRequest = () => {
    return (
      !name.trim() || !enunciated.trim() ||
      !description.trim() ||
      !origin.length > 0 || !date.trim() ||
      !finalDate.trim() || !msjDate.trim() ||
      !msjFinalDate.trim() || !msjConfirmation.trim() ||
      !msjRequestMailed.trim()
    )
  }

  const disabledPayment = () => {
    if (typeDocumentPayment === "111" || typeDocumentPayment === "112") {
      if (!amount) return true;
      if (parseFloat(amount) < 12000) return true;
    }
    return (
      !name.trim() || !enunciated.trim() ||
      !description.trim() ||
      !origin.length > 0 || !date.trim() ||
      !finalDate.trim() || !msjDate.trim() ||
      !msjFinalDate.trim() || !typeDocumentPayment.trim()
    )
  }

  const onError = ({ graphQLErrors }) => {
    setLoadingMutation(false);
    if (graphQLErrors?.length > 0) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: graphQLErrors[0]?.message
      });
    } else {
      Swal.fire("Lo sentimos, ha ocurrido un error.");
    }
  };

  const onCompleted = async ({ crearMultiDocumento }) => {
    await refetch();
    Swal.fire('El documento ha sido creado exitosamente.', '', 'success')
      .then(() => {
        setOpen(false);
        history.push("/dashboard/Categoría/" + category + "/Documento/" + crearMultiDocumento?.id);
      });
  }

  const [mutation] = useMutation(CREATE_DOCUMENT, { onCompleted, onError });
  const { loading, error, data } = useQuery(FIELDS);

  const handleMutationRequest = () => {
    setLoadingMutation(true);
    const fechaInicio = new Date(moment(date));
    fechaInicio.setHours(0, 0, 0, 0);
    const fechaCaducidad = new Date(moment(finalDate));
    fechaCaducidad.setHours(23, 59, 59, 0);
    const input = {
      nombre: name,
      enunciado: enunciated,
      descripcion: description,
      idCategoria: category,
      procedencias: origin,
      fechaInicio: fechaInicio.toISOString(),
      fechaCaducidad: fechaCaducidad.toISOString(),
      mensajeCaducidad: msjFinalDate,
      mensajeFechaInicio: msjDate,
      idTipoDocumento: documentType,
      mensajeCorreoSolicitudEnviada: msjRequestMailed,
      mensajeConfirmacion: msjConfirmation
    }
    if (studentLimit) input.limiteRespuesta = parseInt(studentLimit);

    mutation({ variables: { input } });
  }

  const handleMutationPayment = () => {
    setLoadingMutation(true);
    const fechaInicio = new Date(moment(date));
    fechaInicio.setHours(0, 0, 0, 0);
    const fechaCaducidad = new Date(moment(finalDate));
    fechaCaducidad.setHours(23, 59, 59, 0);
    const input = {
      nombre: name,
      enunciado: enunciated,
      descripcion: description,
      idCategoria: category,
      procedencias: origin,
      fechaInicio: fechaInicio.toISOString(),
      fechaCaducidad: fechaCaducidad.toISOString(),
      mensajeCaducidad: msjFinalDate,
      mensajeFechaInicio: msjDate,
      idTipoDocumento: documentType,
      idTipoDocumentoPago: typeDocumentPayment
    }
    if (studentLimit) input.limiteRespuesta = parseInt(studentLimit);
    if (msjOpenValue.trim()) input.mensajeValorAbierto = msjOpenValue;
    if (typeDocumentPayment === "111" || typeDocumentPayment === "112") input.valor = parseFloat(amount);

    mutation({ variables: { input } });
  }

  return (
    <>
      <Card>
        <Card.Content style={{ marginTop: 36 }} textAlign="center">
          <Button
            style={{ backgroundColor: "#045A73", color: "white" }}
            onClick={() => setOpen(true)} size="huge" circular icon="add" />
        </Card.Content>
      </Card>
      <Modal open={open} onClose={() => setOpen(false)}>
        <Modal.Header>
          Crear documento
        </Modal.Header>
        <Modal.Content>
          <h3>Tipo documento</h3>
          <Dropdown
            selection
            fluid
            placeholder="Seleccione un tipo de documento"
            value={documentType}
            onChange={(e, { value }) => setDocumentType(value)}
            options={options} />
        </Modal.Content>
        {documentType && <Divider />}
        {documentType && <Modal.Content style={{ height: 300 }} scrolling>
          {documentType === "9" && (
            <Segment>
              <Form>
                <Form.Group widths={3}>
                  <Form.Input
                    label="Nombre"
                    value={name}
                    onChange={(e, { value }) => setName(value)} />
                  <Form.Input
                    label="Enunciado"
                    value={enunciated}
                    onChange={(e, { value }) => setEnunciated(value)} />
                  <Form.Input
                    label="Limite de estudiantes (Opcional)"
                    value={studentLimit}
                    type="number"
                    min={0}
                    onChange={(e, { value }) => setStudentLimit(value)} />
                </Form.Group>
              </Form>
              <div style={{ marginTop: 10, marginBottom: 10 }}>
                <label style={{ fontWeight: "bold" }}>Descripción</label>
                <MDEditor
                  value={description}
                  onChange={setDescription}
                />
              </div>
              <Form>
                <Form.Dropdown
                  selection
                  clearable
                  multiple
                  placeholder="Seleccione una procedencia"
                  loading={loading}
                  error={error}
                  label="Procedencias"
                  value={origin}
                  onChange={(e, { value }) => setOrigin(value)}
                  options={data?.procedencia || []} />
                <Form.Group widths={2}>
                  <Form.Input
                    label="Fecha Inicio"
                    value={date}
                    onChange={(e, { value }) => setDate(value)}
                    type="date" />
                  <Form.Input
                    label="Fecha de caducidad"
                    value={finalDate}
                    onChange={(e, { value }) => setFinalDate(value)}
                    type="date" />
                </Form.Group>
                <Form.Group widths={2}>
                  <Form.TextArea
                    label="Mensaje fecha inicio"
                    value={msjDate}
                    onChange={(e, { value }) => setMsjDate(value)} />
                  <Form.TextArea
                    value={msjFinalDate}
                    onChange={(e, { value }) => setMsjFinalDate(value)}
                    label="Mensaje de caducidad" />
                </Form.Group>
                <Form.Group widths={2}>
                  <Form.TextArea
                    value={msjConfirmation}
                    onChange={(e, { value }) => setMsjConfirmation(value)}
                    label="Mensaje de confirmación" />
                  <Form.TextArea
                    value={msjRequestMailed}
                    onChange={(e, { value }) => setMsjRequestMailed(value)}
                    label="Mensaje de solicitud por correo" />
                </Form.Group>
              </Form>
            </Segment>
          )}
          {documentType === "8" && (
            <Segment>
              <Form>
                <Form.Group widths={3}>
                  <Form.Input
                    label="Nombre"
                    value={name}
                    onChange={(e, { value }) => setName(value)} />
                  <Form.Input
                    label="Enunciado"
                    value={enunciated}
                    onChange={(e, { value }) => setEnunciated(value)} />
                  <Form.Input
                    label="Limite de estudiantes (Opcional)"
                    value={studentLimit}
                    type="number"
                    min={0}
                    onChange={(e, { value }) => setStudentLimit(value)} />
                </Form.Group>
              </Form>
              <div style={{ marginTop: 10, marginBottom: 10 }}>
                <label style={{ fontWeight: "bold" }}>Descripción</label>
                <MDEditor
                  value={description}
                  onChange={setDescription}
                />
              </div>
              <Form>
                <Form.Dropdown
                  selection
                  clearable
                  multiple
                  placeholder="Seleccione una procedencia"
                  loading={loading}
                  error={error}
                  label="Procedencias"
                  value={origin}
                  onChange={(e, { value }) => setOrigin(value)}
                  options={data?.procedencia || []} />
                <Form.Dropdown
                  selection
                  clearable
                  fluid
                  placeholder="Seleccione tipo pago"
                  loading={loading}
                  error={error}
                  label="Tipo de pago"
                  value={typeDocumentPayment}
                  onChange={(e, { value }) => setTypeDocumentPayment(value)}
                  options={data?.tipoDocumentoPago || []} />
                {(typeDocumentPayment === "111" || typeDocumentPayment === "112") && (
                  <Form.Input
                    label="Valor del documento"
                    value={amount}
                    error={parseFloat(amount) < 12000 ? "El monto minimo debe ser $12.000" : null}
                    onChange={(e, { value }) => setAmount(value)}
                    type="number" />
                )}
                {(typeDocumentPayment === "110" || typeDocumentPayment === "112") && (
                  <Form.TextArea
                    label="Mensaje valor abierto (Opcional)"
                    value={msjOpenValue}
                    onChange={(e, { value }) => setMsjOpenValue(value)} />
                )}
                <Form.Group widths={2}>
                  <Form.Input
                    label="Fecha Inicio"
                    value={date}
                    onChange={(e, { value }) => setDate(value)}
                    type="date" />
                  <Form.Input
                    label="Fecha de caducidad"
                    value={finalDate}
                    onChange={(e, { value }) => setFinalDate(value)}
                    type="date" />
                </Form.Group>
                <Form.Group widths={2}>
                  <Form.TextArea
                    label="Mensaje fecha inicio"
                    value={msjDate}
                    onChange={(e, { value }) => setMsjDate(value)} />
                  <Form.TextArea
                    value={msjFinalDate}
                    onChange={(e, { value }) => setMsjFinalDate(value)}
                    label="Mensaje de caducidad" />
                </Form.Group>
              </Form>
            </Segment>
          )}
        </Modal.Content>}
        <Modal.Actions>
          {documentType === "9" && <Button
            loading={loadingMutation}
            onClick={handleMutationRequest}
            disabled={disabledRequest() || loadingMutation}
            secondary
            content="Crear" />}
          {documentType === "8" && <Button
            loading={loadingMutation}
            onClick={handleMutationPayment}
            disabled={disabledPayment() || loadingMutation}
            secondary
            content="Crear" />}
          <Button onClick={() => setOpen(false)} content="Cancelar" />
        </Modal.Actions>
      </Modal>
    </>
  );
};

const CREATE_DOCUMENT = gql`
mutation createDocumentRequest($input: CrearMultiDocumentoInput!) {
  crearMultiDocumento(input: $input) {
    id
    nombre
  }
}`;

const FIELDS = gql`
  query {
    procedencia: multiValorParametros(
      filtro: { estado: ACTIVO, idParametro: 1 }
    ) {
      value: id
      text: valorParametro
      key: id
    }
    tipoDocumentoPago: multiValorParametros(
      filtro: { estado: ACTIVO, idParametro: 13 }
    ) {
      value: id
      text: valorParametro
      key: id
    }
  }
`;

export default CreateDocument;