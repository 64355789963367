import React, { useState } from "react";
import { Button, Form, Popup } from "semantic-ui-react";
import { useMutation, gql } from "@apollo/client";
import Swal from "sweetalert2";

const CreateAction = ({ reload }) => {
  const [name, setName] = useState("");

  const onCompleted = async () => {
    setName("");
    await reload();
    Swal.fire({
      icon: 'success',
      title: 'Completado',
      text: 'Se ha creado la acción de manera exitosa.'
    })
  };


  const onError = ({ graphQLErrors }) => {
    if (graphQLErrors) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: graphQLErrors[0]?.message
      });
    } else {
      Swal.fire("Lo sentimos, ha ocurrido un error.");
    }
  };

  const [mutation] = useMutation(CREATE_ACTION, { onCompleted, onError });
  const handleMutation = () => {
    mutation({
      variables: {
        nombre: name
      }
    })
  };
  return (
    <Popup
      on='click'
      trigger={<Form.Button content='Agregar acción' icon='add' />}
      wide>
      <Form>
        <Form.Input
          value={name}
          onChange={(e, { value }) => setName(value)}
          placeholder="Nombre" />
        <Button onClick={handleMutation} disabled={!name.trim()}>Guardar</Button>
      </Form>
    </Popup>
  );
};


const CREATE_ACTION = gql`
  mutation createAction($nombre: String!, $estado: Status) {
    crearMultiAccion(nombre: $nombre, estado: $estado) {
      id
      nombre
      estado
    }
  }
`;


export default CreateAction;