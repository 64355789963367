import { gql, useMutation } from "@apollo/client";
import React, { useState } from "react";
import { Button, Form, Icon, Modal, TextArea } from "semantic-ui-react";
import Swal from "sweetalert2";
const CreateTemplate = ({ reload, }) => {
    const limitDescription = 10000// max->65535;
    const [open, setOpen] = useState(false);
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [loadingSave, setLoadingSave] = useState(false);
    
    const onCompleted = async () => {
        await reload();
        setName("");
        setDescription("");
        setLoadingSave(false);
        Swal.fire({
            icon: 'success',
            title: 'Completado',
            text: 'Se ha creado el usuario de manera exitosa.'
        }).then(() => setOpen(false))
    };


    const onError = ({ graphQLErrors }) => {
        setLoadingSave(false);
        if (graphQLErrors) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: graphQLErrors[0]?.message || "Lo sentimos ha ocurrido un error."
            });
        } else {
            Swal.fire("Lo sentimos, ha ocurrido un error.");
        }
    };

    const [mutation] = useMutation(CREATE_TEMPLATE, { onCompleted, onError });

    const handleMutation = () => {
        const input = {
            nombre: name,
            detalle: description,

        };
        mutation({ variables: { input } });
    }

    const disabled = () => {
        if (name.length<=0) {
            return true;
        }
        if (description.length<=0) {
            return true;
        }
    }

    return (
        <>
            <Form.Button
                icon='add'
                size="mini"
                content="Crear plantilla"
                onClick={() => setOpen(true)} />
            <Modal onClose={() => setOpen(false)} size="tiny" open={open}>
                <Modal.Header>
                    <Icon name='add' /> Crear plantilla
                </Modal.Header>
                <Modal.Content>
                    <Form>
                        <Form.Input
                            value={name}
                            onChange={(e, { value }) => setName(value)}
                            label="Nombre" />
                        <TextArea
                            placeholder='Mensaje de plantilla'
                            value={description}
                            onChange={(e, { value }) => {
                                if (value.length>limitDescription) {
                                    Swal.fire(
                                        'ERROR!',
                                        'LLego al limite de caracteres permitidos',
                                        'error'
                                      )
                                    return;
                                }
                                setDescription(value);
                            }
                        } 
                            rows={20}
                            />
                            <label>
                                {
                                    `Caracteres permitidos: ${limitDescription-description.length}`
                                }
                            </label>
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <Button disabled={disabled() || loadingSave} onClick={handleMutation} content="Crear" />
                    <Button onClick={() => setOpen(false)} content="Cancelar" />
                </Modal.Actions>
            </Modal>
        </>
    );
}


const CREATE_TEMPLATE = gql`
mutation createTemplate($input: CrearMultiPlantillaInput!) {
    crearMultiPlantilla(input: $input) {
        id
        nombre
        detalle
        estado
  }
}`;

export default CreateTemplate;