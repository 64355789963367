import { gql, useMutation } from "@apollo/client";
import React, { useState } from "react";
import { Button, Form, Popup } from "semantic-ui-react";
import Swal from "sweetalert2";
import UpdateOption from "./Update";

const Options = ({ idCampo, options=[], refetch, aforo, limit }) => {

  const [name, setName] = useState("");
  const [enunciated, setEnunciated] = useState("");
  const [value, setValue] = useState("");
  const [loading, setLoading] = useState(false);
  const onCompleted = async () => {
    await refetch();
    setName("");
    setEnunciated("");
    setValue("");
    setLoading(false);
  }

  const onError = ({ graphQLErrors }) => {
    setLoading(false);
    if (graphQLErrors?.length > 0) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: graphQLErrors[0]?.message
      });
    } else {
      Swal.fire("Lo sentimos, ha ocurrido un error.");
    }
  };

  const [mutation] = useMutation(CREATE_OPTION, { onCompleted, onError });

  const handleMutation = () => {
    if (aforo && !validateLimit(value)) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Los aforos no deben sobre pasar el limite'
      });
      return;
    }
    setLoading(true);
    const input = {
      nombre: name,
      enunciado: enunciated,
      idCampo
    }
    if (Number.isInteger(parseInt(value))) {
      input.valor = parseInt(value);
    }
    mutation({ variables: { input } });
  }

  const validateLimit = (valor, id) => {
    let limite = limit;
    options.forEach((option) => {
      if (id!==option.id) {
        limite = limite - option.valor
      }
    })
    limite=limite-valor;
    return limite >= 0
  }

  const soloNumeros = (e) => {
    const key = e.charCode;
    if (!(key >= 48 && key <= 57)) {
      e.preventDefault();
    }
  }

  return (
    <>
      <Form>
        {options.map((option, key) => <UpdateOption aforo={aforo} validateLimit={validateLimit} refetch={refetch} key={key} option={option} num={key} />)}
      </Form>
      <br />
      <Popup
        content={
          <>
            <Form>
              <Form.Input
                value={name}
                onChange={(e, { value }) => setName(value)}
                label='Nombre' />
              <Form.Input
                value={enunciated}
                onChange={(e, { value }) => setEnunciated(value)}
                label='Enunciado' />
              {
                aforo && (
                  <Form.Input
                    value={value}
                    onKeyPress={(e) => { soloNumeros(e) }}
                    onChange={(e, { value }) => setValue(value)}
                    label='Aforos' />
                )
              }
              <Form.Button
                style={{ backgroundColor: "#045A73", color: "white" }}
                loading={loading}
                disabled={!name.trim() || !enunciated.trim() || loading}
                onClick={handleMutation} content='Guardar' />
            </Form>
          </>
        }
        on='click'
        pinned
        trigger={<Button style={{ backgroundColor: "#045A73", color: "white" }} content='Crear opción' />}
      />
    </>
  );
}

const CREATE_OPTION = gql`
mutation createOption($input: CrearMultiOpcionInput!) {
  crearOpcion(input: $input) {
    id
    nombre
    valor
    orden
    enunciado
  }
}`;

export default Options;