import { gql, useMutation } from "@apollo/client";
import React, { useState } from "react";
import { Button, Form, Modal } from "semantic-ui-react";
import Swal from "sweetalert2";
import InputTypePassword from "../../../Components/InputTypePassword";
import { EMAIL_REGEX, PASSWORD_REGEX } from "../../../Utils/Constants";
const passwordError = 'La contraseña debe tener mínimo entre 8 y 15 caracteres, una letra minúscula, una mayúscula, un número y un carácter especial.';
const UpdateAdmin = ({ trigger, admin }) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [document, setDocument] = useState(admin?.documento || "");
  const [firstName, setFirstName] = useState(admin?.primerNombre || "");
  const [firstSurname, setFirstSurname] = useState(admin?.primerApellido || "");
  const [email, setEmail] = useState(admin?.email || "");
  const [password, setPassword] = useState("");
  const [statusCheck, setStatusCheck] = useState("profile");
  const onCompleted = () => {
    setLoading(false);
    setPassword("");
    const text = statusCheck === "profile" ? "Se ha actualizado el usuario exitosamente." : "Se ha actualizado la contraseña del usuario exitosamente."
    Swal.fire({
      icon: 'success',
      title: 'Completado',
      text
    }).then(() => setOpen(false))
  }
  const onError = ({ graphQLErrors }) => {
    setLoading(false);
    if (graphQLErrors) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: graphQLErrors[0]?.message || "Lo sentimos ha ocurrido un error."
      });
    } else {
      Swal.fire("Lo sentimos, ha ocurrido un error.");
    }
  };
  const [mutation] = useMutation(UPDATE_ADMIN, { onCompleted, onError });
  const handleMutation = () => {
    setLoading(true);
    const input = { id: admin?.id };
    if (statusCheck === "profile") {
      if (document) input.documento = document;
      if (firstName) input.primerNombre = firstName;
      if (firstSurname) input.primerApellido = firstSurname;
      if (email) input.email = email;
    }

    if (statusCheck === "password") {
      input.password = password;
    }
    mutation({ variables: { input } });
  }

  const disabled = () => {
    if (statusCheck === "profile") return !document.trim() || !firstName.trim() || !firstSurname.trim() || !EMAIL_REGEX.test(email);
    if (statusCheck === "password") return !PASSWORD_REGEX.test(password);
  }
  return (
    <>
      {React.cloneElement(trigger, { onClick: () => setOpen(true) })}
      <Modal size="tiny" onClose={() => setOpen(false)} open={open} dimmer="inverted">
        <Modal.Header>
          Actualizar usuario
        </Modal.Header>
        <Modal.Content>
          <div style={{ textAlign: "center" }}>
            <Button style={{
              color: "white",
              backgroundColor: "#045A73"
            }} onClick={() => setStatusCheck("profile")} content="Cambiar perfil" />
            <Button style={{
              color: "white",
              backgroundColor: "#045A73"
            }} onClick={() => setStatusCheck("password")} content="Cambiar contraseña" />
          </div>
          {statusCheck === "profile" && (
            <Form>
              <Form.Input
                value={document}
                label="Documento"
                onChange={(e, { value }) => setDocument(value)} />
              <Form.Input
                value={firstName}
                label="Primer nombre"
                onChange={(e, { value }) => setFirstName(value)} />
              <Form.Input
                value={firstSurname}
                label="Primer apellido"
                onChange={(e, { value }) => setFirstSurname(value)} />
              <Form.Input
                value={email}
                label="Correo"
                error={email?.trim() && !EMAIL_REGEX.test(email) ? "Ingresa una dirección de correo electrónico válida." : null}
                onChange={(e, { value }) => setEmail(value)} />
            </Form>
          )}
          {statusCheck === "password" && (
            <Form>
              <InputTypePassword
                label="Contraseña"
                value={password}
                error={password.trim() && !PASSWORD_REGEX.test(password) ? passwordError : null}
                onChange={(e, { value }) => setPassword(value)} />
            </Form>
          )}
        </Modal.Content>
        <Modal.Actions>
          <Button
            style={{
              color: "white",
              backgroundColor: "#045A73"
            }}
            loading={loading}
            disabled={disabled() || loading}
            onClick={handleMutation}
            content="Guardar" />
          <Button
            style={{
              backgroundColor: "#b0193f",
              color: "white"
            }}
            onClick={() => setOpen(false)}
            content="Cancelar" />
        </Modal.Actions>
      </Modal>
    </>
  );
};

const UPDATE_ADMIN = gql`
mutation updateAdmin($input: ActualizarMultiUsuarioAdminInput!) {
  actualizarAdmin(input: $input) {
    id
    nombre
    documento
    primerNombre
    primerApellido
    email
  }
}`;

export default UpdateAdmin;