import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { getData } from './Utils';

const environment = process.env.NODE_ENV;

export const uri = environment === "production" ? "https://solicitudes.multidiomasltda.com:3000" : 'http://localhost:4000';


const httpLink = createHttpLink({
  uri: `${uri}/graphql`
});

const authLink = setContext((_, { headers }) => {
  const token = getData("tokenMulti");
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    }
  }
});

export const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache()
});