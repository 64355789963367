import { gql, useMutation } from "@apollo/client";
import React, { useState } from "react";
import { Button, Popup, Input } from "semantic-ui-react";
import Swal from "sweetalert2";

const CreateCategorie = ({ reload }) => {
  const [name, setName] = useState("");
  const [loading, setLoading] = useState(false);

  const onCompleted = async () => {
    await reload();
    setLoading(false);
    setName("");
  };

  const onError = ({ graphQLErrors }) => {
    setLoading(false);
    if (graphQLErrors.length > 0) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: graphQLErrors[0]?.message
      });
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: "Lo sentimos, ha ocurrido un error."
      });
    }
  };


  const [mutation] = useMutation(CREATE_CATEGORIE, {
    onCompleted,
    onError
  });

  const handleMutation = () => {
    mutation({ variables: { nombre: name } });
  }

  return (
    <Popup
      wide
      trigger={<Button size="mini" icon="add" content="Crear categoría" />}
      on='click'>
      <h4>Nombre de la categoría</h4>
      <Input value={name} onChange={(e, { value }) => setName(value)} />
      <br />
      <br />
      <Button
        loading={loading}
        disabled={!name.trim() || loading}
        content="Guardar"
        onClick={handleMutation} />
    </Popup>
  )
}

const CREATE_CATEGORIE = gql`
  mutation createCategorie($nombre: SearchString!) {
    crearMultiCategoria(nombre: $nombre) {
      id
      nombre
      orden
    }
  }
`;

export default CreateCategorie;