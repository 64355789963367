import { gql, useQuery } from "@apollo/client";
import { useState } from "react";
import { Button, Form, Label } from "semantic-ui-react";
import CustomTable from "../../../Components/CustomTablePagination";
import CustomXls from "../../../Components/CustomXlsx";
import { getData } from "../../../Utils";
import CreateAdmin from "./Create";
import UpdateAdmin from "./Update";


const Admins = ({ permissions }) => {
  const [status] = useState("ACTIVO");
  const me = getData("me")

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [fakeName, setFakeName] = useState("");
  const [rol, setRol] = useState("3");
  const [loadingRefetch, setLoadingRefetch] = useState(false);
  const [fakeEmail, setFakeEmail] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(10);

  const filtro = { estado: status, idGrupoUsuario: rol };

  if (name) filtro.nombre = name;
  if (email) filtro.email = email;

  const { loading, error, data, refetch } = useQuery(
    ADMINS,
    {
      variables: { filtro, pagina: {number: currentPage, limit: pageLimit} },
      fetchPolicy: "network-only"
    });

  const CREATE = permissions?.some(value => value?.permiso.nombre === "Crear");
  const EDIT = permissions?.some(value => value?.permiso.nombre === "Editar");
  const DELETE = permissions?.some(value => value?.permiso?.nombre === "Eliminar");

  let optionsRoles = [];

  if (me?.multiGrupoUsuario?.id !== "4") {
    optionsRoles = data?.multiGrupoUsuarios?.filter(val => val.text !== "Estudiante" && val.text !== "Super Admin");
  } else {
    optionsRoles = data?.multiGrupoUsuarios?.filter(val => val.text !== "Estudiante");
  }
  const columns = [
    {
      header: 'Nombre',
      accessor: 'nombre'
    },
    {
      header: 'Documento',
      accessor: 'documento'
    },
    {
      header: 'Correo',
      accessor: 'email'
    },
    {
      header: 'Estado',
      accessor: 'estado'
    }
  ];

  if (EDIT) columns.push(
    {
      header: 'Acción de editar',
      render: data => (
        <UpdateAdmin
          admin={data}
          trigger={
            <Button
              style={{ backgroundColor: "#045A73", color: "white" }}
              size="mini"
              icon="edit"
              content="Editar" />
          } />
      )
    }
  );

  if (DELETE && status !== "ELIMINADO") columns.push(
    {
      header: 'Acción de eliminar',
      render: data => ""
    }
  );

  const cleanFilter = () => {
    setName("");
    setEmail("");
    setFakeEmail("");
    setFakeName("");
  }

  const handleRefetch = async () => {
    setLoadingRefetch(true);
    await refetch();
    setLoadingRefetch(false);
  }

  return (
    <div>
      <h1>Usuarios</h1>
      <Form>
        <Form.Group inline>
          <Form.Field>
            <Label content={`Cantidad (${data?.multiUsuarios?.count || 0})`} />
          </Form.Field>
          <Form.Dropdown
            label="Grupo usuario"
            value={rol}
            loading={loading}
            error={error}
            onChange={(e, { value }) => setRol(value)}
            options={optionsRoles || []} />
        </Form.Group>
        <Form.Group inline>
          {CREATE && <CreateAdmin loading={loading} error={error} optionsRoles={optionsRoles} reload={refetch} />}
          <Form.Input
            size="mini"
            action={{
              size: "mini",
              icon: 'search',
              content: 'Buscar',
              onClick: () => setName(fakeName)
            }}
            value={fakeName}
            onChange={(e, { value }) => setFakeName(value)}
            placeholder="Búsqueda por nombre" />
          <Form.Input
            size="mini"
            action={{
              icon: 'search',
              size: "mini",
              content: 'Buscar',
              onClick: () => setEmail(fakeEmail)
            }}
            value={fakeEmail}
            onChange={(e, { value }) => setFakeEmail(value)}
            placeholder="Búsqueda por email" />
          <CustomXls
            columns={columns}
            fileName="Usuarios"
            data={data?.multiUsuarios || []} />
          <Form.Button
            icon='undo'
            content="Actualizar"
            loading={loadingRefetch}
            size="mini"
            onClick={handleRefetch} />
          <Form.Button
            icon='trash'
            size="mini"
            content="Vaciar filtros"
            onClick={cleanFilter} />
        </Form.Group>
      </Form>
      <CustomTable
        data={data?.multiUsuarios?.items || []}
        loading={loading || loadingRefetch}
        error={error}
        columns={columns}
        currentPage={currentPage}
        onPageChange={(e, { activePage }) => setCurrentPage(activePage)}
        pageLimit={pageLimit}
        setPageLimit={setPageLimit}
        setCurrentPage={setCurrentPage}
        totalPages={data?.multiUsuarios?.totalPages || 0} />
    </div>
  );

}

const ADMINS = gql`
query userAdmin($filtro: MultiUsuarioFiltro!, $pagina: PageInput!) {
  multiGrupoUsuarios(filtro: { estado: ACTIVO }) {
    value: id
    text: nombre
    key: id
  }
  multiUsuarios(filtro: $filtro, pagina: $pagina) {
    count
    page {
      number
      limit
    }
    items {
      id
      nombre
      primerNombre
      primerApellido
      documento
      email
      estado
    }
    totalPages
  }
}`;

export default Admins;