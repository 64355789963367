import { gql, useQuery } from "@apollo/client";
import moment from "moment";
import React from "react";
import { Button, Modal } from "semantic-ui-react";
import CustomTable from "../../../../Components/CustomTable";

const ManagementHistory = ({ id }) => {
  const filtro = { estado: "ACTIVO", idSolicitud: id };
  const { loading, error, data } = useQuery(MANAGEMENT_HISTORY, { variables: { filtro }, fetchPolicy: "network-only" });
  const columns = [
    {
      header: 'Id',
      accessor: 'id'
    },
    {
      header: 'Fecha de modificación',
      accessor: 'fechaModificacion',
      render: date => moment(date).format('DD-MM-YYYY h:mm:ss A')
    },
    {
      header: 'Gestionada por',
      accessor: 'usuario.nombre'
    },
    {
      header: 'Estado solicitud anterior',
      accessor: 'estadoSolicitudAnterior.valorParametro'
    },
    {
      header: 'Estado solicitud actual',
      accessor: 'estadoSolicitudActual.valorParametro'
    },
  ];

  return (
    <CustomTable
      data={data?.multiHistorialSolicitudes}
      loading={loading}
      error={error}
      columns={columns}
    />
  );
}

const Content = ({ id }) => (
  <Modal dimmer="inverted" trigger={<Button size="mini" content="Ver historial" style={{ backgroundColor: "#045A73", color: "white" }} />}>
    <Modal.Header>Historial de solicitud</Modal.Header>
    <Modal.Content scrolling>
      <ManagementHistory id={id} />
    </Modal.Content>
  </Modal>
);

const MANAGEMENT_HISTORY = gql`
query managementHistory($filtro: MultiHistorialSolicitudFiltro!) {
  multiHistorialSolicitudes(filtro: $filtro) {
    id
    fechaModificacion
    usuario {
      id
      nombre
    }
    estadoSolicitudAnterior {
      id
      valorParametro
    }
    estadoSolicitudActual {
      id
      valorParametro
    }
  }
}`;

export default Content;