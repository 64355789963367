import { gql, useMutation, useQuery } from "@apollo/client";
import React, { useState, useEffect } from "react";
import { Button, Form, Label, Modal, Segment } from "semantic-ui-react";
import Swal from "sweetalert2";
import { getData } from "../../../../Utils";

const Management = ({ id, trigger, options = [], statusRequest }) => {
  const { data } = useQuery(GET_TEMPLATES, {
    variables: {
      filtro: {
        estado: "ACTIVO",
      },
    },
    fetchPolicy: "network-only",
  });

  const me = getData("me");
  const userGroup = me?.multiGrupoUsuario?.id;
  const [open, setOpen] = useState(false);
  const [requestStatus, setRequestStatus] = useState("");
  const [msj, setMsj] = useState("");
  const [image, setImage] = useState({});
  const [observation, setObservation] = useState("");
  const [loading, setLoading] = useState(false);
  const [listTemplates, setTamplates] = useState([]);

  useEffect(() => {
    const getListTamples = () => {
      if (data) {
        const templates = data?.multiPlantillas?.items;
        const list = templates.map((item) => ({
          text: item.nombre,
          value: item.detalle,
          key: item.id,
        }));

        return [
          {
            text: "",
            value: "",
            key: 0,
          },
          ...list,
        ];
      }
      return [];
    };

    if (listTemplates.length <= 0) {
      const list = getListTamples();
      setTamplates(list);
    }
  }, [listTemplates, data]);

  const disabled = () => {
    if (!requestStatus.trim()) return true;
    if (!msj.trim()) return true;
  };

  const optionsFilter = () => {
    if (statusRequest === "Enviada")
      return options.filter((v) => v.value !== "12");
    if (statusRequest === "En tramite")
      return options.filter((v) => v.value !== "12" && v.value !== "113");
    if (statusRequest === "Aprobada") {
      if (userGroup === "1" || userGroup === "4")
        return options.filter((v) => v.value === "14");
    }
    if (statusRequest === "Rechazada") {
      if (userGroup === "1" || userGroup === "4")
        return options.filter((v) => v.value === "13");
    }
  };

  const disabledManagement = () => {
    if (userGroup === "1" || userGroup === "4") return false;
    else return statusRequest === "Aprobada" || statusRequest === "Rechazada";
  };

  const onError = ({ graphQLErrors }) => {
    setLoading(false);
    if (graphQLErrors) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: graphQLErrors[0]?.message,
      });
    } else {
      Swal.fire("Lo sentimos, ha ocurrido un error.");
    }
  };

  const onCompleted = () => {
    Swal.fire(
      "La solicitud ha sido gestionada exitosamente.",
      "",
      "success"
    ).then(() => {
      setMsj("");
      setLoading(false);
      setRequestStatus("");
      setObservation("");
      setOpen(false);
      setImage({})
    });
  };

  const [mutation] = useMutation(MANAGEMENT_REQUEST, { onCompleted, onError });

  const handleMutation = () => {
    const variables = {
      id,
      mensaje: msj,
      idEstadoSolicitud: requestStatus,
      imagen: JSON.stringify(image)
    };
    if (observation.trim()) variables.observacion = observation;
    setLoading(true);

    mutation({ variables });
  };

  const checkImage = (imageInput) => {
    if (imageInput.files[0]) {
      const file = imageInput.files[0];
      var pattern = /image-*/;

      if (!file.type.match(pattern)) {
        return false;
      }
      return file;
    }
  };

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const changeFile = async (e) => {
    let check = checkImage(e.target);
    if (check) {
      let base64 = await toBase64(check)
      let image = { base64, name:check.name}
      setImage(image)
    } else {
      Swal.fire({
        icon: "error",
        text: "El archivo cargado no es una imagen, por favor cargue una imagen.",
        showConfirmButton: false,
        timer: 2000,
      });
    }
  };

  return (
    <>
      {React.cloneElement(trigger, {
        onClick: () => setOpen(true),
        disabled: disabledManagement(),
      })}
      <Modal
        dimmer="inverted"
        onClose={() => setOpen(false)}
        size="mini"
        open={open}
      >
        <Modal.Header>
          Gestión de solicitud
          <Label
            style={{ backgroundColor: "#045A73", color: "white" }}
            attached="top right"
          >
            Estado actual: {statusRequest}
          </Label>
        </Modal.Header>
        <Modal.Content>
          <Form>
            <Form.Dropdown
              selection
              placeholder="Filtro de estado solicitud"
              label="Estado solicitud"
              value={requestStatus}
              onChange={(e, { value }) => setRequestStatus(value)}
              options={optionsFilter()}
            />
            <Form.Dropdown
              selection
              placeholder="Lista de Plantillas"
              label="Plantilla (Opcional)"
              onChange={(e, { value }) => setMsj(value)}
              options={listTemplates}
            />
            <Segment basic textAlign={"center"}>
              <Button as="label" htmlFor="file" type="button">
                Cargar imagen (Opcional)
              </Button><br/><br/>
              <Label>Tenga en cuenta que la imagen que cargue tendrá un ancho máximo igual al ancho correo enviado, así que por favor revise con cuidado las dimensiones de la imagen.</Label>
              <input
                type="file"
                id="file"
                hidden
                onChange={changeFile}
                accept="image/*"
              />
              
              {image.base64 && (<img alt="imagenSubida" style={{maxWidth:"100%"}} src={image.base64}/>)}
            </Segment>
            <Form.TextArea
              value={msj}
              onChange={(e, { value }) => setMsj(value)}
              label="Mensaje solicitud"
            />
            <Form.TextArea
              value={observation}
              onChange={(e, { value }) => setObservation(value)}
              label="Observación (Opcional)"
            />
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button
            style={{ backgroundColor: "#045A73", color: "white" }}
            onClick={handleMutation}
            loading={loading}
            content="Gestionar"
            disabled={disabled() || loading}
          />
          <Button
            style={{ backgroundColor: "#b0193f", color: "white" }}
            onClick={() => setOpen(false)}
            content="Cancelar"
          />
        </Modal.Actions>
      </Modal>
    </>
  );
};

const MANAGEMENT_REQUEST = gql`
  mutation managementRequest(
    $id: ID!
    $idEstadoSolicitud: ID!
    $mensaje: String
    $observacion: String
    $imagen: String
  ) {
    gestionSolicitud(
      id: $id
      idEstadoSolicitud: $idEstadoSolicitud
      mensaje: $mensaje
      observacion: $observacion
      imagen: $imagen
    ) {
      id
      estadoSolicitud {
        id
        valorParametro
      }
    }
  }
`;

const GET_TEMPLATES = gql`
  query getTemplates($filtro: MultiPlantillaFiltro!) {
    multiPlantillas(filtro: $filtro) {
      items {
        id
        nombre
        detalle
        estado
      }
    }
  }
`;

export default Management;
