import { useMutation, useQuery } from "@apollo/client";
import gql from "graphql-tag";
import React, { useState } from "react";
import { Route } from "react-router-dom";
import { Button, Dimmer, Grid, Icon, Image, Loader, Menu, Message, Segment } from "semantic-ui-react";
import { getData, setData } from "../../Utils";
import Actions from "./Actions";
import Modules from "./Modules";
import Permissions from "./Permissions";
import Documents from "./Documents";
import Swal from "sweetalert2";
import RequestReport from "./Home/Requests";
import PaymentReport from "./Home/Payments";
import Admins from "./Admins";
import { client } from "../../client";
import Students from "./Students";
import LOGO_MULTI from "../../assets/logo-multi.png";
import Reports from "./Reports";
import Categories from "./Categories";
import Categorie from "./Categories/Categorie";
import Document from "./Categories/Categorie/Document";
import Student from "./Student";
import Templates from "./templates/index.js";

const icons = {
  "Módulos": "tablet",
  "Acciones": "cogs",
  "Permisos": "lock open",
  "Usuarios": "user",
  "Estudiantes": "users",
  "Solicitudes": "folder open",
  "Pagos": "money bill alternate",
  "Reportes": "line graph",
  "Categorías": "sitemap",
  "Estudiante": "address book",
  "Plantillas": "clone"
}

const Dashboard = ({ match, history }) => {
  const [active, setActive] = useState("");
  const me = getData("me");
  const { data, error, loading, refetch } = useQuery(MODULES, { variables: { idGrupoUsuario: me?.multiGrupoUsuario?.id } });

  const onCompleted = () => {
    setData("me", null);
    setData("tokenMulti", null);
    client.clearStore();
    history.replace("/");
  };

  const handleItemClick = (e, { name }) => {
    setActive(name);
    history.replace(`${match.url}/${name}`);
  };


  const onError = ({ graphQLErrors }) => {
    if (graphQLErrors) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: graphQLErrors[0]?.message
      });
    } else {
      Swal.fire("Lo sentimos, ha ocurrido un error.");
    }
  };

  const [logout] = useMutation(LOGOUT, { onCompleted, onError });
  const handleLogout = () => {
    Swal.fire({
      title: '¿Deseas finalizar la sesión?',
      showDenyButton: true,
      confirmButtonText: 'Aceptar',
      denyButtonText: 'Cancelar',
    }).then((result) => {
      if (result.isConfirmed) {
        logout();
      }
    })
  }

  if (loading) return (
    <Segment style={{ minHeight: "100vh" }}>
      <Dimmer inverted active>
        <Loader inverted >Cargando</Loader>
      </Dimmer>
    </Segment>
  );

  if (error || !data) return (
    <Segment style={{ minHeight: "100vh" }}>
      <Message>Ha ocurrido un error.</Message>
    </Segment>
  );

  return (
    <div>
      <Menu fluid stackable attached='top'>
        <Menu.Item link>
          <Image size="small" src={LOGO_MULTI} alt="logo multidiomas" />
        </Menu.Item>
        <Menu.Menu position="right">
          <Menu.Item style={{ color: "#b0193f" }} name="profile">
            <Icon size="large" name="user" />
            {me?.nombre}
          </Menu.Item>
          <Menu.Item>
            <Button
              style={{ backgroundColor: "#045A73", color: "white" }}
              onClick={handleLogout}
              icon="sign-out alternate"
              color="google plus" />
          </Menu.Item>
        </Menu.Menu>
      </Menu>
      <div style={{ backgroundColor: "#045A73", height: 7 }}></div>
      <Grid stackable>
        <Grid.Column width={2}>
          <Menu inverted fluid style={{ backgroundColor: "#b0193f", minHeight: "100%", borderRadius: 0 }} icon vertical>
            {data?.multiModulos?.map((module, key) => {
              const READ = module?.permisos?.some(value => value?.permiso.nombre === "Leer");
              return READ && (
                <Menu.Item
                  key={key}
                  name={module?.nombre}
                  active={active === module?.nombre}
                  onClick={handleItemClick}
                  as='a'>
                  {icons[module?.nombre] && <Icon name={icons[module?.nombre]} />}
                  {module?.nombre}
                </Menu.Item>
              );
            })}
          </Menu>
        </Grid.Column>
        <Grid.Column width={14}>
          <div style={{ minHeight: "100vh", marginTop: 20 }}>
            {data?.multiModulos?.map((module, key) => {
              const READ = module?.permisos?.some(value => value?.permiso.nombre === "Leer");
              return READ && (
                <div key={key}>
                  <Route
                    key={key}
                    path={`${match.path}/${module?.nombre}`}>
                    <Content
                      history={history}
                      path={`${match.path}/${module?.nombre}`}
                      module={module}
                      refetch={refetch} />
                  </Route>
                  {module?.nombre === "Categorías" && (
                    <>
                      <Route
                        exact
                        component={Categorie}
                        path={`/dashboard/Categoría/:id`} />
                      <Route
                        exact
                        component={Document}
                        path={`/dashboard/Categoría/:id/Documento/:idDocument`} />
                    </>
                  )}
                </div>
              );
            })}
            <Route
              component={Documents}
              path={`${match.path}/documents`} />
          </div>
        </Grid.Column>
      </Grid>
    </div>
  );
};

const Content = ({ module, refetch, path, history }) => {
  switch (module?.nombre) {
    case "Módulos":
      return <Modules permissions={module?.permisos} />;
    case "Acciones":
      return <Actions permissions={module?.permisos} />;
    case "Permisos":
      return <Permissions reloadPermissions={refetch} permissions={module?.permisos} />;
    case "Usuarios":
      return <Admins permissions={module?.permisos} />
    case "Estudiantes":
      return <Students permissions={module?.permisos} />
    case "Estudiante":
      return <Student permissions={module?.permisos} />
    case "Plantillas":
      return <Templates permissions={module?.permisos} />;
    case "Solicitudes":
      return <RequestReport />;
    case "Pagos":
      return <PaymentReport />;
    case "Reportes":
      return <Reports />;
    case "Categorías":
      return <Categories permissions={module?.permisos} history={history} path={path} />
    default:
      return null;
  }
}

const LOGOUT = gql`
  mutation logout {
    cerrarSesion
  }
`;

const MODULES = gql`
query getModules($idGrupoUsuario: ID!) {
  multiModulos(filtro: { estado: ACTIVO }) {
    id
    nombre
    permisos(idGrupoUsuario: $idGrupoUsuario) {
      id
      permiso: multiAccion {
        id
        nombre
      }
    }
  }
}`;

export default Dashboard;