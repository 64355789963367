import { gql, useQuery } from "@apollo/client";
import React from "react";
import { Menu, Message, Icon, Container } from "semantic-ui-react";
import CreateCategorie from "./Create";


const Categories = ({ history, permissions }) => {
  const CREATE = permissions?.some(value => value?.permiso.nombre === "Crear");
  const goToCategorie = id => {
    history.push("/dashboard/Categoría/" + id);
  }

  const { loading, error, data, refetch } = useQuery(CATEGORIES, { fetchPolicy: "network-only" });

  if (loading) return "Cargando...";

  if (error) return <Message>Lo sentimos, ha ocurrido un error.</Message>;

  const { multiCategorias } = data;


  return (
    <Container>
      <h1>Categorías</h1>
      {CREATE && <CreateCategorie reload={refetch} />}
      {multiCategorias.length <= 0 && (
        <Message>No hay categorías para mostrar.</Message>
      )}
      <Menu size="massive" vertical>
        {multiCategorias.map(categorie => (
          <Menu.Item onClick={() => goToCategorie(categorie?.id)} link key={categorie?.id}>
            {categorie?.nombre}
            <Icon name="arrow circle right" />
          </Menu.Item>
        ))}
      </Menu>
    </Container>
  );
}

const CATEGORIES = gql`
  query getCategories {
    multiCategorias(filtro: { estado: ACTIVO }) {
      id
      nombre
      orden
    }
  }
`;




export default Categories;