import { gql, useQuery } from "@apollo/client";
import React, { useState } from "react";
import { Breadcrumb, Button, Form, Label } from "semantic-ui-react";
import CustomTable from "../../../../Components/CustomTablePagination";
import DocumentResponse from "../../../../Components/DocumentResponse";
import { formatCurrency, paymentMethod } from "../../../../Utils";
import moment from "moment";
import CustomXls from "../../../../Components/CustomXlsx";
import FormFilter from "../../../../Components/FormFilter";
import CreateObservation from "./CreateObservation";

const options = [
  { text: "PENDIENTE", value: "PENDIENTE", key: "PENDIENTE" },
  { text: "RECHAZADO", value: "RECHAZADO", key: "RECHAZADO" },
  { text: "APROBADO", value: "APROBADO", key: "APROBADO" },
];

const PaymentReport = () => {
  const [origin, setOrigin] = useState("");
  const [form, setForm] = useState("");
  const [paymentStatus, setPaymentStatus] = useState("");
  const [loadingRefetch, setLoadingRefetch] = useState(false);
  const [reference, setReference] = useState({ value: "", fakeValue: "" });
  const [document, setDocument] = useState({ value: "", fakeValue: "" });
  const [documentName, setDocumentName] = useState({ value: "", fakeValue: "" });
  const [currentPage, setCurrentPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(10);
  const filtro = { estado: "ACTIVO" };
  if (reference.value.trim()) filtro.referencia = reference.value;
  if (document.value.trim()) filtro.documentoUsuario = document.value;
  if (documentName.value.trim()) filtro.nombreDocumento = documentName.value;
  if (origin) filtro.idProcedencia = origin;
  if (form) filtro.idDocumento = form;
  if (paymentStatus) filtro.estadoPago = paymentStatus;
  const { loading, error, data, refetch } = useQuery(PAYMENT_REPORT, { variables: { filtro, pagina: {number: currentPage, limit: pageLimit} }, fetchPolicy: "network-only" });


  const columns = [
    {
      header: 'No. Documento',
      accessor: 'usuario.documento'
    },
    {
      header: 'Nombre',
      accessor: 'usuario.nombre'
    },
    {
      header: 'Formulario',
      accessor: 'documento.nombre'
    },
    {
      header: 'Procedencia',
      accessor: 'usuario.valorProcedencia.valorParametro',
    },
    {
      header: 'Estado de la transacción',
      accessor: 'estadoPago'
    },
    {
      header: 'Valor pagado',
      accessor: 'valor',
      render: amount => formatCurrency(amount)
    },
    {
      header: 'Valor del documento',
      accessor: 'documento.valor',
      render: amount => formatCurrency(amount)
    },
    {
      header: 'Metodo de pago',
      accessor: 'metodoPago',
      render: data => paymentMethod(data)
    },
    {
      header: 'Moneda',
      accessor: 'moneda'
    },
    {
      header: 'Referencia de pago',
      accessor: 'referenciaPago'
    },
    {
      header: 'Fecha de creación',
      accessor: 'fechaHora',
      render: date => moment(date).format('DD-MM-YYYY h:mm:ss A')
    },
    {
      header: 'Fecha de confirmación',
      accessor: 'fechaConfirmacion',
      render: date => date ? moment(date).format('DD-MM-YYYY h:mm:ss A') : ""
    },
    {
      header: 'Detalle',
      accessor: 'id',
      render: id => (
        <DocumentResponse
          type="payments"
          trigger={
            <Button size="mini" style={{ backgroundColor: "#045A73", color: "white" }} icon="arrow alternate circle right" />
          }
          id={id} />
      )
    },
    {
      header: 'Crear observación',
      accessor: 'id',
      render: id => (
        <CreateObservation id={id} />
      )
    },
    {
      header: "Observación",
      accessor: "observacion"
    }
  ];

  const cleanFilter = () => {
    setOrigin("");
    setForm("");
    setReference({ value: "", fakeValue: "" });
    setPaymentStatus("");
    setDocumentName({ value: "", fakeValue: "" });
    setDocument({ value: "", fakeValue: "" });
  }

  const handleRefetch = async () => {
    setLoadingRefetch(true);
    await refetch();
    setLoadingRefetch(false);
  }

  const sections = [
    { key: 'Pagos', content: 'Pagos', active: true },
  ]

  return (
    <>
      <Breadcrumb size="massive" icon='right angle' sections={sections} />
      <br />
      <br />
      <Form>
        <Form.Group inline>
          <Form.Field>
            <Label content={`Cantidad (${data?.multiPagos?.count || 0})`} />
          </Form.Field>
          <Form.Dropdown
            clearable
            placeholder="Filtro de procedencia"
            loading={loading}
            error={error}
            label="Procedencia"
            value={origin}
            onChange={(e, { value }) => setOrigin(value)}
            options={data?.procedencia || []} />
          <Form.Dropdown
            clearable
            placeholder="Filtro estado de la transacción"
            label="Estado de la transacción"
            value={paymentStatus}
            onChange={(e, { value }) => setPaymentStatus(value)}
            options={options} />
          <FormFilter
            value={form}
            onChange={(e, { value }) => setForm(value)}
            type="payments" />
        </Form.Group>
        <Form.Group inline>
          <Form.Input
            size="mini"
            value={reference?.fakeValue}
            onChange={(e, { value }) => setReference({ ...reference, fakeValue: value })}
            action={<Button onClick={() => setReference({ ...reference, value: reference.fakeValue })} size="mini" icon="search" content="Referencia" />}
            placeholder='Buscar por referencia' />
          <Form.Input
            size="mini"
            value={document?.fakeValue}
            onChange={(e, { value }) => setDocument({ ...document, fakeValue: value })}
            action={<Button onClick={() => setDocument({ ...document, value: document.fakeValue })} size="mini" icon="search" content="No. Documento" />}
            placeholder='Buscar por número documento' />
          {/* <Form.Input
            size="mini"
            value={documentName?.fakeValue}
            onChange={(e, { value }) => setDocumentName({ ...documentName, fakeValue: value })}
            action={<Button onClick={() => setDocumentName({ ...documentName, value: documentName.fakeValue })} size="mini" icon="search" content="Formulario" />}
         placeholder='Buscar por formulario' />*/}
          <CustomXls
            columns={columns}
            fileName="Pagos"
            data={data?.multiPagos?.items || []} />
          <Form.Button
            icon='undo'
            content="Actualizar"
            loading={loadingRefetch}
            size="mini"
            onClick={handleRefetch} />
          <Form.Button
            compact
            icon="trash"
            onClick={cleanFilter}
            size="mini"
            content="Vaciar filtros"
          />
        </Form.Group>
      </Form>
      <CustomTable
        data={data?.multiPagos?.items || []}
        loading={loading || loadingRefetch}
        error={error}
        columns={columns}
        currentPage={currentPage}
        onPageChange={(e, { activePage }) => setCurrentPage(activePage)}
        pageLimit={pageLimit}
        setPageLimit={setPageLimit}
        setCurrentPage={setCurrentPage}
        totalPages={data?.multiPagos?.totalPages || 0}
      />
    </>
  );
}


const PAYMENT_REPORT = gql`
query paymentReport($filtro: MultiPagoFiltro! $pagina: PageInput!) {
  procedencia: multiValorParametros(
    filtro: { estado: ACTIVO, idParametro: 1 }
  ) {
    value: id
    text: valorParametro
    key: id
  }
  multiPagos(filtro: $filtro , orden: { direccion: ASC, campo: FECHA_CREACION }, pagina: $pagina) {
    count
    page {
      number
      limit
    }
    totalPages
    items {
      id
    fechaHora
    fechaConfirmacion
    estadoPago
    referenciaPago
    moneda
    valor
    observacion
    metodoPago
    usuario {
      id
      documento
      valorProcedencia {
        id
        valorParametro
      }
      nombre
    }
    documento {
      id
      nombre
      valor
    }
    }
  }
}`;

export default PaymentReport;