import { gql, useMutation, useQuery } from "@apollo/client";
import React, { useState } from "react";
import { Button, Form, Modal } from "semantic-ui-react";
import Swal from "sweetalert2";

const typeText = {
  Texto: "Texto",
  Opcion: "Opción",
  Radio: "Radio",
  Archivo: "Archivo"
}

const CreateField = ({ trigger, idDocument, refetch }) => {
  const [open, setOpen] = useState(false);
  const [enunciated, setEnunciated] = useState("");
  const [name, setName] = useState("");
  const [type, setType] = useState("");
  const [required, setRequired] = useState(false);
  const [loadingSave, setLoadingSave] = useState(false);


  const onError = ({ graphQLErrors }) => {
    setLoadingSave(false);
    if (graphQLErrors) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: graphQLErrors[0]?.message || "Lo sentimos, ocurrio un error inesperado."
      });
    } else {
      Swal.fire("Lo sentimos, ha ocurrido un error.");
    }
  };

  const onCompleted = async () => {
    await refetch();
    Swal.fire('La pregunta se ha creado exitosamente.', '', 'success')
      .then(() => {
        setEnunciated("");
        setName("");
        setType("");
        setRequired(false);
        setLoadingSave(false);
        setOpen(false);
      });
  }

  const { loading, error, data } = useQuery(TYPE_FIELD);
  const [mutation] = useMutation(CREATE_DOCUMENT_FIELD, { onCompleted, onError });


  let options = data?.tipoCampo || [];
  options = options.map(value => ({ ...value, text: typeText[value?.text] || value?.text }));

  const handleMutation = () => {
    setLoadingSave(true);
    const input = {
      nombre: name,
      idDocumento: idDocument,
      enunciado: enunciated,
      tipo: type,
      obligatorio: required
    };
    mutation({ variables: { input } })
  }

  return (
    <>
      {React.cloneElement(trigger, { onClick: () => setOpen(true) })}
      <Modal onClose={() => setOpen(false)} open={open}>
        <Modal.Header>Crear pregunta</Modal.Header>
        <Modal.Content>
          <Form>
            <Form.TextArea
              label="Nombre"
              style={{ maxHeight: 50, minHeight: 50 }}
              value={name}
              onChange={(e, { value }) => setName(value)}
              placeholder="Agregar Nombre" />
            <Form.TextArea
              label="Enunciado"
              style={{ maxHeight: 50, minHeight: 50 }}
              value={enunciated}
              onChange={(e, { value }) => setEnunciated(value)}
              placeholder="Agregar Enunciado" />
            <Form.Dropdown
              selection
              label="Tipo de pregunta"
              value={type}
              onChange={(e, { value }) => setType(value)}
              loading={loading}
              placeholder="Seleccionar un tipo"
              error={error}
              options={options} />
            <Form.Checkbox
              checked={required}
              onChange={(e, { checked }) => setRequired(checked)}
              toggle
              label="Obligatorio" />
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button
            disabled={!name.trim() || !enunciated.trim() || !type.trim()}
            loading={loadingSave}
            onClick={handleMutation}
            content="Crear" />
          <Button content="Cancelar" onClick={() => setOpen(false)} />
        </Modal.Actions>
      </Modal>
    </>
  );
};


const TYPE_FIELD = gql`
query typeField {
  tipoCampo: multiValorParametros(filtro: { estado: ACTIVO, idParametro: 2 }) {
    value: id
    text: valorParametro
    key: id
  }
}`;


const CREATE_DOCUMENT_FIELD = gql`
mutation createDocumentField($input: CrearMultiCampoInput!) {
  crearMultiCampo(input: $input) {
    id
    nombre
    obligatorio
    tipo {
      id
      valorParametro
    }
    enunciado
  }
}`;



export default CreateField;