import { gql, useMutation } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { Button, Form, Grid, Label, Segment } from 'semantic-ui-react';
import { getData, setData } from '../../Utils';
import { client } from "../../client";
import Swal from 'sweetalert2';
import LOGO_MULTI from "../../assets/logo-multi.png";
import { EMAIL_REGEX } from '../../Utils/Constants';

const Login = ({ history }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  useEffect(() => {
    if (getData("tokenMulti")) history.replace("/dashboard/Solicitudes");
  }, [history]);
  const onCompleted = ({ crearSesionAdmin }) => {
    setData("tokenMulti", crearSesionAdmin);
    client.query({ query: ME })
      .then(res => {
        if (res.data) {
          setData("me", res.data.me);
          history.replace("/dashboard/Solicitudes");
        }
      })
      .catch(err => console.log(err))
  };
  const onError = ({ graphQLErrors }) => {
    if (graphQLErrors) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: graphQLErrors[0]?.message
      });
    } else {
      Swal.fire("Lo sentimos, ha ocurrido un error.");
    }
  };

  const [login] = useMutation(LOGIN, { onCompleted, onError });
  const handleMutation = () => {
    login({
      variables: {
        email,
        password
      }
    });
  };
  return (
    <Grid className="login" textAlign='center' style={{ height: '100vh' }} verticalAlign='middle'>
      <Grid.Column style={{ maxWidth: 450 }}>
        <Form size='large'>
          <Segment stacked>
            <div style={{ margin: 20, textAlign: 'center' }}>
              <img src={LOGO_MULTI} alt="logo multidiomas" />
            </div>
            <Label style={{ backgroundColor: "#b0193f", color: "white" }} attached='top right'>Administrador</Label>
            <Form.Input
              fluid icon='user'
              value={email}
              iconPosition='left'
              error={email.trim() && !EMAIL_REGEX.test(email) ? "Correo no valido" : null}
              placeholder='Correo'
              onChange={(e, { value }) => setEmail(value)} />
            <Form.Input
              fluid
              value={password}
              icon='lock'
              iconPosition='left'
              placeholder='Contraseña'
              type='password'
              onChange={(e, { value }) => setPassword(value)} />
            <Button disabled={!EMAIL_REGEX.test(email) || !password.trim()} onClick={handleMutation} style={{ backgroundColor: "#b0193f", color: "white" }} fluid size='large'>
              Iniciar sesion
           </Button>
          </Segment>
        </Form>
      </Grid.Column>
    </Grid>
  );
};

const LOGIN = gql`
  mutation crearSesion($email: EmailAddress!, $password: String!) {
    crearSesionAdmin(email: $email, password: $password)
  }
`;

const ME = gql`
  query me {
    me {
      id
      nombre
      documento
      email
      multiGrupoUsuario {
        id
        nombre
      }
    }
  }
`;

export default Login;