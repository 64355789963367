import { gql, useMutation } from "@apollo/client";
import React, { useState } from "react";
import { Segment, Form } from "semantic-ui-react";
import Swal from "sweetalert2";

const UpdateOption = ({ option, num, refetch, aforo, validateLimit }) => {
  const [name, setName] = useState(option?.nombre || "");
  const [enunciated, setEnunciated] = useState(option?.enunciado || "");
  const [value, setValue] = useState(option?.valor||"");
  const [order, setOrder] = useState(option?.orden || 0);
  const [loading, setLoading] = useState(false);
  const onCompleted = async () => {
    await refetch();
    setLoading(false);
  }

  const onError = ({ graphQLErrors }) => {
    setLoading(false);
    if (graphQLErrors?.length > 0) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: graphQLErrors[0]?.message
      });
    } else {
      Swal.fire("Lo sentimos, ha ocurrido un error.");
    }
  };
  const [mutation] = useMutation(UPTADE_OPTION, { onCompleted, onError });
  const handleMutation = () => {
    if (aforo && !validateLimit(parseInt(value),option.id)) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Los aforos no deben sobre pasar el limite'
      });
      return;
    }
    setLoading(true);
    mutation({
      variables: {
        input: {
          id: option?.id,
          nombre: name,
          enunciado: enunciated,
          orden: parseInt(order),
          valor: parseInt(value)
        }
      }
    })
  }

  const soloNumeros = (e) => {
    const key = e.charCode;
    if (!(key >= 48 && key <= 57)) {
      e.preventDefault();
    }
  }

  return (
    <Segment>
      <h5>{num + 1}.</h5>
      <Form.Group widths='equal'>
        <Form.Input
          label='Nombre'
          value={name}
          onChange={(e, { value }) => setName(value)}
          placeholder='Nombre' />
        <Form.Input
          label='Enunciado'
          value={enunciated}
          onChange={(e, { value }) => setEnunciated(value)}
          placeholder='Last name' />
        <Form.Input
          label='Orden'
          type="number"
          min={0}
          onChange={(e, { value }) => setOrder(value)}
          value={order}
          placeholder='Orden' />
      </Form.Group>
      {
          aforo && (
            <Form.Input
              value={value}
              onKeyPress={(e) => { soloNumeros(e) }}
              onChange={(e, { value }) => setValue(value)}
              label='Aforos' />
          )
        }
      <Form.Button
        style={{ backgroundColor: "#045A73", color: "white" }}
        loading={loading}
        disabled={!name.trim() || !enunciated.trim() || !Number.isInteger(parseInt(order)) || loading}
        onClick={handleMutation} content='Guardar' />
    </Segment>
  );
}

const UPTADE_OPTION = gql`
  mutation updateOption($input: ActualizarMultiOpcionInput!) {
    actualizarOpcion(input: $input) {
      id
      nombre
      orden
      enunciado
      valor
    }
  }
`;

export default UpdateOption;