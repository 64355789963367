import React from "react";
import { Route } from "react-router";
import { Button, Card, Icon } from "semantic-ui-react";
import PaymentsDocument from "./Payments";
import RequestsDocument from "./Requests";
import DocumentRequest from "./Requests/DocumentRequest";

const Documents = ({ history }) => {

  return (
    <>
      <h1 style={{ margin: 0, marginBottom: 10 }}>Documentos</h1>
      <Card.Group>
        <Card>
          <Card.Content>
            <Card.Header> Tipo solicitud</Card.Header>
            <Card.Meta>Listado de documentos</Card.Meta>
            <Card.Description>
              <div>
                <Icon size="large" name='file alternate outline' />
                <Icon size="large" name='file text' />
                <Icon size="large" name='time' />
              </div>
            </Card.Description>
          </Card.Content>
          <Card.Content extra>
            <div className='ui two buttons'>
              <Button onClick={() => history.push('documents/requests')} icon="arrow alternate circle right" />
            </div>
          </Card.Content>
        </Card>
        <Card>
          <Card.Content>
            <Card.Header>Tipo pago</Card.Header>
            <Card.Meta>Listado de documentos</Card.Meta>
            <Card.Description>
              <div>
                <Icon size="large" name='file alternate outline' />
                <Icon size="large" name='file text' />
                <Icon size="large" name='time' />
              </div>
            </Card.Description>
          </Card.Content>
          <Card.Content extra>
            <div className='ui two buttons'>
              <div className='ui two buttons'>
                <Button onClick={() => history.push('documents/payments')} icon="arrow alternate circle right" />
              </div>
            </div>
          </Card.Content>
        </Card>
      </Card.Group>
    </>
  )
}


const Content = ({ match }) => (
  <>
    <Route
      exact
      component={Documents}
      path={`${match.path}`} />
    <Route
      component={RequestsDocument}
      path={`${match.path}/requests`} />
    <Route
      component={DocumentRequest}
      path={`${match.path}/request/:id`} />
    <Route
      component={PaymentsDocument}
      path={`${match.path}/payments`} />
  </>
);


export default Content;