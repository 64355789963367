import React from "react";
import { Form } from "semantic-ui-react";

const Selector = ({
  data = [],
  value,
  label = "",
  onChange,
  placeholder = "",
  clearable = true,
  loading = false,
  error = false
}) => {
  const actions = data.map(value => ({
    text: value.nombre,
    value: value.id,
    key: value.id
  }));

  if (loading) return (
    <Form.Dropdown
      label={label}
      placeholder={placeholder}
      loading
      options={[]}
      selection />
  );

  if (error) return (
    <Form.Dropdown
      label={label}
      placeholder={placeholder}
      error
      options={[]}
      selection />
  );

  return (
    <Form.Dropdown
      label={label}
      placeholder={placeholder}
      value={value}
      clearable={clearable}
      onChange={onChange}
      options={actions}
      selection />
  );
};

export default Selector;