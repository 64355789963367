import { gql, useQuery } from "@apollo/client";
import React, { useState } from "react";
import { Breadcrumb, Button, Card, Form } from "semantic-ui-react";
import CustomCardList from "../../../../Components/CustomCardList";
import CreateDocument from "./CreateDocument";
const options = [
  { text: "Activo", value: "ACTIVO", key: "ACTIVO" },
  { text: "Inactivo", value: "INACTIVO", key: "INACTIVO" },
  { text: "Eliminado", value: "ELIMINADO", key: "ELIMINADO" },
];
const RequestsDocument = ({ history }) => {
  const [status, setStatus] = useState("ACTIVO");
  const filtro = { estado: status, tipoDocumento: "9" };
  const { loading, error, data } = useQuery(REQUESTS_DOCUMENT, { variables: { filtro }, fetchPolicy: "network-only" });
  const sections = [
    { key: 'Documentos', content: 'Documentos', link: true, onClick: () => history.goBack() },
    { key: 'Solicitudes', content: 'Solicitudes', active: true },
  ]

  return (
    <>
      <Breadcrumb size="massive" icon='right angle' sections={sections} />
      <br />
      <br />
      <Form>
        <Form.Group>
          <Form.Dropdown
            selection
            label="Estado"
            value={status}
            onChange={(e, { value }) => setStatus(value)}
            options={options} />
        </Form.Group>
      </Form>
      <CustomCardList
        loading={loading}
        error={error}
        data={data?.multiDocumentos}>
        {({ list }) => (
          <Card.Group stackable>
            {list.map((document, key) => (
              <Card key={key}>
                <Card.Content>
                  <Card.Header>{document?.enunciado}</Card.Header>
                  <Card.Meta>{document?.estado}</Card.Meta>
                  <Card.Description>
                  Procedencias: <strong>{document?.procedencias?.map(value => value?.procedencia?.valorParametro)?.join(' , ')}</strong>
                  </Card.Description>
                </Card.Content>
                <Card.Content extra>
                  <div className='ui two buttons'>
                    <Button
                      onClick={() => history.push(`request/${document?.id}`)}
                      icon="arrow alternate circle right" />
                  </div>
                </Card.Content>
              </Card>
            ))}
            <CreateDocument
              dataValue={data}
              loadingData={loading}
              errorData={error}
              trigger={
                <Card>
                  <Card.Content style={{ marginTop: 36 }} textAlign="center">
                    <Button size="huge" circular icon="add" />
                  </Card.Content>
                </Card>
              } />
          </Card.Group>
        )}
      </CustomCardList>
    </>
  )
}

const REQUESTS_DOCUMENT = gql`
query requestsDocument($filtro: MultiDocumentoFiltro!){
  categoria: multiCategorias(filtro: { estado: ACTIVO }) {
    value: id
    text: nombre
    key: id
  }
  procedencia: multiValorParametros(
    filtro: { estado: ACTIVO, idParametro: 1 }
  ) {
    value: id
    text: valorParametro
    key: id
  }
  multiDocumentos(filtro:$filtro){
    id
    enunciado
    procedencias {
      id
      procedencia {
        id
        valorParametro
      }
    }
    estado
  }
}`;

export default RequestsDocument;