import { useQuery, gql } from "@apollo/client";
import React, { useState } from "react";
import { Form } from "semantic-ui-react";
import CustomTable from "../../../Components/CustomTable";
import Selector from "./Selector";
import CreatePermit from "./Create";
import UpdatePermit from "./Update";
import DeletePermit from "./Delete";
const options = [
  { text: "Activo", value: "ACTIVO", key: "ACTIVO" },
  { text: "Inactivo", value: "INACTIVO", key: "INACTIVO" },
  { text: "Eliminado", value: "ELIMINADO", key: "ELIMINADO" },
];

const Permissions = ({ permissions, reloadPermissions }) => {
  const [status, setStatus] = useState("ACTIVO");
  const [action, setAction] = useState("");
  const [userGroup, setUserGroup] = useState("");
  const [module, setModule] = useState("");
  const filtro = { estado: status };
  if (action) filtro.idAccion = action;
  if (module) filtro.idModulo = module;
  if (userGroup) filtro.idGrupoUsuario = userGroup;
  const { data, loading, error, refetch } = useQuery(
    GET_PERMISSIONS_AND_FILTERS,
    {
      variables: { filtro },
      fetchPolicy: "network-only"
    }
  );

  const CREATE = permissions?.some(value => value?.permiso.nombre === "Crear");
  const EDIT = permissions?.some(value => value?.permiso.nombre === "Editar");
  const DELETE = permissions?.some(value => value?.permiso?.nombre === "Eliminar");

  const columns = [
    {
      header: 'Id',
      accessor: 'id'
    },
    {
      header: 'Estado',
      accessor: 'estado'
    },
    {
      header: 'Grupo usuario',
      accessor: 'multiGrupoUsuario.nombre'
    },
    {
      header: 'Modulo',
      accessor: 'multiModulo.nombre'
    },
    {
      header: 'Acción',
      accessor: 'multiAccion.nombre'
    }
  ];

  if (EDIT) columns.push(
    {
      header: 'Acción de editar',
      render: permit => (
        <UpdatePermit
          data={data}
          permit={permit}
          error={error}
          reload={refetch}
          reloadPermissions={reloadPermissions}
          loading={!data ? true : false} />
      )
    }
  );

  if (DELETE && status !== "ELIMINADO") columns.push(
    {
      header: 'Acción de eliminar',
      render: data => (
        <DeletePermit
          reload={refetch}
          reloadPermissions={reloadPermissions}
          id={data?.id} />
      )
    }
  );

  return (
    <div>
      <h1>Permisos</h1>
      <Form>
        <Form.Group inline>
          {CREATE && (
            <CreatePermit
              data={data}
              loading={!data ? true : false}
              error={error}
              reloadPermissions={reloadPermissions}
              reload={refetch} />
          )}
          <Form.Dropdown
            selection
            label="Estado"
            value={status}
            onChange={(e, { value }) => setStatus(value)}
            options={options} />
          <Selector
            label="Acción"
            loading={!data ? true : false}
            error={error}
            value={action}
            onChange={(e, { value }) => setAction(value)}
            data={data?.multiAcciones}
            placeholder="Búsqueda por acción" />
          <Selector
            label="Grupo de usuario"
            loading={!data ? true : false}
            error={error}
            value={userGroup}
            onChange={(e, { value }) => setUserGroup(value)}
            data={data?.multiGrupoUsuarios}
            placeholder="Búsqueda por grupo usuario" />
          <Selector
            label="Módulo"
            loading={!data ? true : false}
            error={error}
            value={module}
            onChange={(e, { value }) => setModule(value)}
            data={data?.multiModulos}
            placeholder="Búsqueda por módulo" />
        </Form.Group>
      </Form>
      <CustomTable
        data={data?.multiPermisos}
        loading={loading}
        error={error}
        columns={columns}
      />
    </div>
  );
};

const GET_PERMISSIONS_AND_FILTERS = gql`
  query getPermissionsAndFilters($filtro: MultiPermisoFiltro!) {
    multiPermisos(filtro: $filtro) {
      id
      multiAccion {
        id
        nombre
      }
      multiModulo {
        id
        nombre
      }
      multiGrupoUsuario {
        id
        nombre
      }
      estado
    }
    multiModulos(filtro: { estado: ACTIVO }) {
      id
      nombre
    }
    multiAcciones(filtro: { estado: ACTIVO }) {
      id
      nombre
    }
    multiGrupoUsuarios(filtro: { estado: ACTIVO }) {
      id
      nombre
    }
  }
`;

export default Permissions;