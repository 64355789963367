import { gql, useMutation } from "@apollo/client";
import React, { useState } from "react";
import { Button, Form, Icon, Modal } from "semantic-ui-react";
import Swal from "sweetalert2";
import InputTypePassword from "../../../Components/InputTypePassword";
import { EMAIL_REGEX, PASSWORD_REGEX } from "../../../Utils/Constants";
const passwordError = 'La contraseña debe tener mínimo entre 8 y 15 caracteres, una letra minúscula, una mayúscula, un número y un carácter especial.';
const CreateAdmin = ({ reload, optionsRoles = [], loading, error }) => {

  const [open, setOpen] = useState(false);
  const [document, setDocument] = useState("");
  const [firstName, setFirstName] = useState("");
  const [firstSurname, setFirstSurname] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [rol, setRol] = useState("");
  const [loadingSave, setLoadingSave] = useState(false);

  const onCompleted = async () => {
    await reload();
    setDocument("");
    setFirstName("");
    setFirstSurname("");
    setRol("");
    setPassword("");
    setEmail("");
    setLoadingSave(false);
    Swal.fire({
      icon: 'success',
      title: 'Completado',
      text: 'Se ha creado el usuario de manera exitosa.'
    }).then(() => setOpen(false))
  };


  const onError = ({ graphQLErrors }) => {
    setLoadingSave(false);
    if (graphQLErrors) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: graphQLErrors[0]?.message || "Lo sentimos ha ocurrido un error."
      });
    } else {
      Swal.fire("Lo sentimos, ha ocurrido un error.");
    }
  };

  const disabled = () => {
    if (!document.trim() || !EMAIL_REGEX.test(email) || !firstSurname.trim() || !firstName.trim() || !rol.trim()) return true;
    if (!password.trim()) return true;
    if (!PASSWORD_REGEX.test(password)) return true;
  }

  const [mutation] = useMutation(CREATE_ADMIN, { onCompleted, onError });
  const validatePassword = () => {
    if (password.trim() && !PASSWORD_REGEX.test(password)) return passwordError;
    return null;
  }

  const handleMutation = () => {
    const input = {
      documento: document,
      primerNombre: firstName,
      primerApellido: firstSurname,
      email,
      idGrupoUsuario: rol,
      password
    };
    mutation({ variables: { input } });
  }

  return (
    <>
      <Form.Button
        icon='add'
        size="mini"
        content="Crear usuario"
        onClick={() => setOpen(true)} />
      <Modal onClose={() => setOpen(false)} size="tiny" open={open}>
        <Modal.Header>
          <Icon name='add' /> Crear usuario
        </Modal.Header>
        <Modal.Content>
          <Form>
            <Form.Input
              value={document}
              onChange={(e, { value }) => setDocument(value)}
              label="Documento de identidad" />
            <Form.Input
              value={firstName}
              onChange={(e, { value }) => setFirstName(value)}
              label="Primer nombre" />
            <Form.Input
              value={firstSurname}
              onChange={(e, { value }) => setFirstSurname(value)}
              label="Primer apellido" />
            <Form.Input
              value={email}
              error={email.trim() && !EMAIL_REGEX.test(email) ? "Ingresa una dirección de correo electrónico válida." : null}
              onChange={(e, { value }) => setEmail(value)}
              label="Correo" />
            <Form.Dropdown
              value={rol}
              selection
              loading={loading}
              error={error}
              onChange={(e, { value }) => setRol(value)}
              options={optionsRoles || []}
              label="Grupo usuario" />
            <InputTypePassword
              placeholder="Contraseña *"
              value={password}
              label="Contraseña"
              error={validatePassword()}
              required
              onChange={(e, { value }) => setPassword(value)} />
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button disabled={disabled() || loadingSave} onClick={handleMutation} content="Crear" />
          <Button onClick={() => setOpen(false)} content="Cancelar" />
        </Modal.Actions>
      </Modal>
    </>
  );
}


const CREATE_ADMIN = gql`
mutation createAdmin($input: CrearMultiUsuarioAdminInput!) {
  crearAdmin(input: $input) {
    id
    documento
    primerNombre
    primerApellido
    email
    estado
  }
}`;

export default CreateAdmin;