import { useQuery, gql, useMutation } from "@apollo/client";
import React, { useState } from "react";
import {
  Button,
  Container,
  Dropdown,
  Form,
  Grid,
  Header,
  Icon,
  Input,
  Message,
  Table,
} from "semantic-ui-react";
import Swal from "sweetalert2";
const gender = [
  { text: "Masculino", key: 1, value: "Masculino" },
  { text: "Femenino", key: 1, value: "Femenino" },
  { text: "Otro", key: 1, value: "Otro" },
];
const host = process.env.NODE_ENV === 'production' ? "https://solicitudes.multidiomasltda.com:3000" : "http://localhost:4000";
const Student = ({ permissions }) => {
  const EDIT = permissions?.some((value) => value?.permiso.nombre === "Editar");
  const [search, setSearch] = useState({});
  const [document, setDocument] = useState("");
  const [email, setEmail] = useState("");
  const cleanFilter = () => {
    setEmail("");
    setDocument("");
    setSearch({});
  };
  return (
    <Container text>
      <h1>Estudiante</h1>
      <Form>
        <Form.Group inline>
          <Form.Input
            size="mini"
            action={{
              size: "mini",
              icon: "search",
              content: "Buscar",
              onClick: () => setSearch({ ...search, document }),
            }}
            value={document}
            onChange={(e, { value }) => setDocument(value?.trim())}
            placeholder="No.documento"
          />
          <Form.Input
            size="mini"
            action={{
              icon: "search",
              size: "mini",
              content: "Buscar",
              onClick: () => setSearch({ ...search, email }),
            }}
            value={email}
            onChange={(e, { value }) => setEmail(value?.trim())}
            placeholder="Búsqueda por email"
          />
          <Form.Button
            icon="trash"
            size="mini"
            content="Vaciar filtros"
            onClick={cleanFilter}
          />
        </Form.Group>
      </Form>
      <br />
      {Object.keys(search)?.length > 0 ? (
        <SearchStudent actionEdit={EDIT} search={search} />
      ) : (
        <Message>
          Para realizar una búsqueda utilice los filtros en pantalla.
        </Message>
      )}
    </Container>
  );
};

const InputOrText = ({ onChange, value, showEdit = false, data = [] }) => {
  if (showEdit) {
    if (!data.length) return <Input fluid value={value} onChange={onChange} />;

    return (
      <Dropdown
        value={value}
        fluid
        selection
        options={data}
        onChange={onChange}
      />
    );
  }

  return value;
};

const SearchStudent = ({ search, actionEdit }) => {
  const { loading, error, data } = useQuery(ESTUDIANTE, {
    variables: {
      filtro: search,
    },
    fetchPolicy: "network-only",
  });

  if (loading) return "Cargando...";
  if (error) return <Message>Lo sentimos, ha ocurrido un error.</Message>;

  const { multiUsuario, procedencia, tipoDocumento } = data;

  if (!multiUsuario)
    return <Message>No se ha encontrado el estudiante.</Message>;

  return (
    <InfoUser
      user={multiUsuario}
      origin={procedencia}
      actionEdit={actionEdit}
      typeDocument={tipoDocumento}
    />
  );
};

const InfoUser = ({ user, origin, typeDocument, actionEdit }) => {
  const [checkEdit, setCheckEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState({
    documento: user?.documento || "",
    email: user?.email || "",
    primerNombre: user?.primerNombre || "",
    segundoNombre: user?.segundoNombre || "",
    primerApellido: user?.primerApellido || "",
    segundoApellido: user?.segundoApellido || "",
    telefono: user?.telefono || "",
    idTipoDocumento: user?.tipoDocumento?.id || "",
    valorProcedencia: user?.valorProcedencia?.id || "",
    genero: user?.genero || "",
    url_imagen: user?.url_imagen || "",
  });

  const onCompleted = (data) => {
    setLoading(false);
    setCheckEdit(false);
    Swal.fire({
      icon: "success",
      title: "Completado",
      text: "Estudiante actualizado exitosamente.",
    });
    setState({
      documento: data?.actualizarMultiUsuario?.documento || "",
      email: data?.actualizarMultiUsuario?.email || "",
      primerNombre: data?.actualizarMultiUsuario?.primerNombre || "",
      segundoNombre: data?.actualizarMultiUsuario?.segundoNombre || "",
      primerApellido: data?.actualizarMultiUsuario?.primerApellido || "",
      segundoApellido: data?.actualizarMultiUsuario?.segundoApellido || "",
      telefono: data?.actualizarMultiUsuario?.telefono || "",
      idTipoDocumento: data?.actualizarMultiUsuario?.tipoDocumento?.id || "",
      valorProcedencia:
        data?.actualizarMultiUsuario?.valorProcedencia?.id || "",
      genero: data?.actualizarMultiUsuario?.genero || "",
      url_imagen: data?.actualizarMultiUsuario?.url_imagen || "",
    });
  };
  const onError = ({ graphQLErrors }) => {
    setLoading(false);
    if (graphQLErrors) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: graphQLErrors[0]?.message,
      });
    } else {
      Swal.fire("Lo sentimos, ha ocurrido un error.");
    }
  };

  const [mutation] = useMutation(UPDATE_STUDENT, {
    onCompleted,
    onError,
  });
  const handleMutation = () => {
    setLoading(true);
    mutation({
      variables: {
        input: {
          id: user?.id,
          ...state,
        },
      },
    });
  };
  const handleDelete = () => {
    Swal.fire({
      text: "¿Está seguro de eliminar la foto del estudiante?",
      confirmButtonText: "Sí",
      cancelButtonText: "Cancelar",
      showCancelButton: true,
      icon: "warning",
    }).then((res) => {
      if (res.isConfirmed) {
        setLoading(true);
        mutation({
          variables: {
            input: {
              id: user?.id,
              deleteImage: true,
            },
          },
        });
      }
    });
  };

  const disabled = () =>
    !state?.email?.trim() ||
    !state?.documento?.trim() ||
    !state?.primerNombre?.trim() ||
    !state?.primerApellido?.trim() ||
    loading;
  return (
    <Container text>
      <Grid>
        <Grid.Row>
          <Grid.Column width={8}>
            <Header style={{ textAlign: "center" }}>
              {state?.url_imagen ? (
                <>
                  <img
                    src={`${host}${state.url_imagen}`}
                    style={{ width: "12rem" }}
                    alt="profile"
                  />
                  <br />
                </>
              ) : (
                <Icon name="user" />
              )}
              {user?.nombre}
            </Header>
          </Grid.Column>
          {actionEdit && (
            <Grid.Column width={8}>
              <Button
                fluid
                content="Editar"
                onClick={() => setCheckEdit(true)}
                icon="edit"
              />
              <br />
              {state.url_imagen && (
                <Button
                  fluid
                  content="Eliminar foto"
                  onClick={() => handleDelete()}
                  icon="delete"
                />
              )}
            </Grid.Column>
          )}
        </Grid.Row>
      </Grid>
      <Table celled>
        <Table.Body>
          <Table.Row>
            <Table.Cell>Documento</Table.Cell>
            <Table.Cell>
              <InputOrText
                showEdit={checkEdit}
                onChange={(e, { value }) =>
                  setState({ ...state, documento: value })
                }
                value={state?.documento}
              />
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Tipo documento</Table.Cell>
            <Table.Cell>
              <InputOrText
                showEdit={checkEdit}
                data={typeDocument || []}
                onChange={(e, { value }) =>
                  setState({ ...state, idTipoDocumento: value })
                }
                value={
                  !checkEdit
                    ? user?.tipoDocumento?.valorParametro
                    : state?.idTipoDocumento
                }
              />
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Primer Nombre</Table.Cell>
            <Table.Cell>
              <InputOrText
                showEdit={checkEdit}
                onChange={(e, { value }) =>
                  setState({ ...state, primerNombre: value })
                }
                value={state?.primerNombre}
              />
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Segundo Nombre</Table.Cell>
            <Table.Cell>
              <InputOrText
                showEdit={checkEdit}
                onChange={(e, { value }) =>
                  setState({ ...state, segundoNombre: value })
                }
                value={state?.segundoNombre}
              />
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Primer Apellido</Table.Cell>
            <Table.Cell>
              <InputOrText
                showEdit={checkEdit}
                onChange={(e, { value }) =>
                  setState({ ...state, primerApellido: value })
                }
                value={state?.primerApellido}
              />
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Segundo Apellido</Table.Cell>
            <Table.Cell>
              <InputOrText
                showEdit={checkEdit}
                onChange={(e, { value }) =>
                  setState({ ...state, segundoApellido: value })
                }
                value={state?.segundoApellido}
              />
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Correo Electrónico</Table.Cell>
            <Table.Cell>
              <InputOrText
                showEdit={checkEdit}
                onChange={(e, { value }) =>
                  setState({ ...state, email: value })
                }
                value={state?.email}
              />
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Teléfono</Table.Cell>
            <Table.Cell>
              <InputOrText
                showEdit={checkEdit}
                onChange={(e, { value }) =>
                  setState({ ...state, telefono: value })
                }
                value={state?.telefono}
              />
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Género</Table.Cell>
            <Table.Cell>
              <InputOrText
                showEdit={checkEdit}
                data={gender}
                onChange={(e, { value }) =>
                  setState({ ...state, genero: value })
                }
                value={!checkEdit ? user?.genero : state?.genero}
              />
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Procedencia</Table.Cell>
            <Table.Cell>
              <InputOrText
                showEdit={checkEdit}
                data={origin || []}
                onChange={(e, { value }) =>
                  setState({ ...state, valorProcedencia: value })
                }
                value={
                  !checkEdit
                    ? user?.valorProcedencia?.valorParametro
                    : state?.valorProcedencia
                }
              />
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
      {checkEdit && (
        <Button
          loading={loading}
          disabled={disabled()}
          content="Guardar"
          onClick={handleMutation}
        />
      )}
    </Container>
  );
};

const ESTUDIANTE = gql`
  query ($filtro: MultiUsuarioInput!) {
    procedencia: multiValorParametros(
      filtro: { estado: ACTIVO, idParametro: 1 }
    ) {
      value: id
      text: valorParametro
      key: id
    }
    tipoDocumento: multiValorParametros(
      filtro: { estado: ACTIVO, idParametro: 5 }
    ) {
      value: id
      text: valorParametro
      key: id
    }
    multiUsuario(filtro: $filtro) {
      id
      documento
      nombre
      primerNombre
      segundoNombre
      primerApellido
      segundoApellido
      email
      telefono
      url_imagen
      genero
      valorProcedencia {
        id
        valorParametro
      }
      tipoDocumento {
        id
        valorParametro
      }
    }
  }
`;

const UPDATE_STUDENT = gql`
  mutation ($input: ActualizarMultiUsuarioInput!) {
    actualizarMultiUsuario(input: $input) {
      id
      documento
      tipoDocumento {
        id
        valorParametro
      }
      nombre
      primerNombre
      segundoNombre
      primerApellido
      segundoApellido
      email
      telefono
      genero
      url_imagen
      valorProcedencia {
        id
        valorParametro
      }
    }
  }
`;

export default Student;
