import { gql, useQuery } from "@apollo/client";
import React from "react";
import { Form } from "semantic-ui-react";

const FormFilter = ({ type = "request", value, onChange }) => {
  const variables = { tipoDocumento: type === "request" ? 9 : 8 }
  const { loading, error, data } = useQuery(GET_FORMS, { variables });

  return (
    <Form.Dropdown
      search
      scrolling
      loading={loading}
      error={error}
      value={value}
      onChange={onChange}
      label="Formulario"
      placeholder="Búsqueda por formulario"
      options={data?.multiDocumentos || []} />
  );
};

const GET_FORMS = gql`
query getForms($tipoDocumento: ID) {
  multiDocumentos(filtro: { estado: ACTIVO, tipoDocumento: $tipoDocumento }) {
    value: id
    text: nombre
    key: id
  }
}
`;

export default FormFilter;