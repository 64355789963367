import React, { useState } from "react";
import MDEditor from '@uiw/react-md-editor';
import { Breadcrumb, Button, Container, Dimmer, Form, Loader, Message, Segment } from "semantic-ui-react";
import { gql, useQuery } from "@apollo/client";
import TypeInputs from "../../../../../Components/TypeInputs";
import CreateField from "./CreateField";


const DocumentRequest = ({ history, match: { params: { id } } }) => {

  const sections = [
    { key: 'Documentos', content: 'Documentos', link: true, onClick: () => history.push('/dashboard/documents/list') },
    { key: 'Solicitudes', content: 'Solicitudes', link: true, onClick: () => history.goBack() },
    { key: 'Solicitud', content: 'Solicitud', active: true },
  ]

  const { loading, error, data, refetch } = useQuery(DOCUMENT_REQUEST, { variables: { id }, fetchPolicy: "network-only" });

  if (loading) return (
    <div>
      <Breadcrumb size="massive" icon='right angle' sections={sections} />
      <Dimmer active inverted>
        <Loader size='large'>Cargando</Loader>
      </Dimmer>
    </div>
  );

  if (error) return (
    <div style={{ margin: 30 }}>
      <Breadcrumb size="massive" icon='right angle' sections={sections} />
      <Message>Ha ocurrido un error.</Message>
    </div>
  );


  return (
    <>
      <Breadcrumb size="massive" icon='right angle' sections={sections} />
      <Content refetch={refetch} data={data?.multiDocumento} />
    </>
  )
}

const Content = ({ data, refetch }) => {
  const [enunciated, setEnunciated] = useState(data?.enunciado || "");
  const [description, setDescription] = useState(data?.descripcion || "");

  return (
    <Container>
      <Segment style={{ margin: 20 }}>
        <Form >
          <Form.Input
            label="Enunciado"
            value={enunciated}
            onChange={(e, value) => setEnunciated(value)}
            fluid size='huge'
            placeholder='Escriba un enunciado para su documento' />
        </Form>
        <div style={{ marginTop: 20 }}>
          <h5>Descripción</h5>
          <MDEditor
            value={description}
            onChange={setDescription}
          />
          <div style={{ marginTop: 20 }}>
            <h2>Preguntas</h2>
            {data?.campos?.map((field, key) => <TypeInputs refetch={refetch} input={field} key={key} />)}
            <Segment style={{ marginTop: 20 }}>
              <h4>Crear Pregunta</h4>
              <div style={{ textAlign: 'center' }}>
                <CreateField
                  refetch={refetch}
                  idDocument={data?.id}
                  trigger={
                    <Button circular icon="add" />
                  } />
              </div>
            </Segment>
          </div>
        </div>
      </Segment>
    </Container>
  )
}

const DOCUMENT_REQUEST = gql`
query DocumentRequest($id: ID!) {
  multiDocumento(id: $id) {
    id
    nombre
    enunciado
    descripcion
    campos {
      id
      nombre
      obligatorio
      opciones {
        id
        nombre
        enunciado
        orden
      }
      tipo {
        id
        valorParametro
      }
      enunciado
    }
  }
}`;


export default DocumentRequest;