import { gql, useMutation } from "@apollo/client";
import React, { useState } from "react";
import { Button, Form, Icon, Modal } from "semantic-ui-react";
import Swal from "sweetalert2";
import Selector from "./Selector";

const options = [
  { text: "Activo", value: "ACTIVO", key: "ACTIVO" },
  { text: "Inactivo", value: "INACTIVO", key: "INACTIVO" },
  { text: "Eliminado", value: "ELIMINADO", key: "ELIMINADO" },
];

const UpdatePermit = ({
  reloadPermissions,
  reload,
  data,
  error = false,
  loading = false,
  permit
}) => {
  const [open, setOpen] = useState(false);
  const [loadingSave, setLoadingSave] = useState(false);
  const [status, setStatus] = useState(permit?.estado || "");
  const [action, setAction] = useState(permit?.multiAccion?.id || "");
  const [userGroup, setUserGroup] = useState(permit?.multiGrupoUsuario?.id || "");
  const [module, setModule] = useState(permit?.multiModulo?.id || "");
  const onCompleted = async () => {
    await reload();
    await reloadPermissions();
    Swal.fire({
      icon: 'success',
      title: 'Completado',
      text: 'Se ha actualizado el permiso de manera exitosa.'
    })
      .then(() => setOpen(false));
  };
  const onError = ({ graphQLErrors }) => {
    setLoadingSave(false);
    if (graphQLErrors) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: graphQLErrors[0]?.message
      });
    } else {
      Swal.fire("Lo sentimos, ha ocurrido un error.");
    }
  };
  const [mutation] = useMutation(UPDATE_PERMIT, { onCompleted, onError });
  const handleMutation = () => {
    mutation({
      variables: {
        input: {
          id: permit.id,
          idAccion: action,
          idModulo: module,
          idGrupoUsuario: userGroup,
          estado: status
        }
      }
    });
  };

  const onClose = () => {
    setStatus(permit?.estado || "");
    setUserGroup(permit?.multiGrupoUsuario?.id || "");
    setModule(permit?.multiModulo?.id || "");
    setAction(permit?.multiAccion?.id || "");
    setOpen(false);
  };

  return (
    <>
      <Form.Button
        icon='edit'
        onClick={() => setOpen(true)}
        content='Editar' />
      <Modal onClose={onClose} size="tiny" open={open}>
        <Modal.Header>
          <Icon name='add' /> Actualizar permiso
        </Modal.Header>
        <Modal.Content>
          <Form>
            <Selector
              clearable={false}
              loading={loading}
              label="Elegir acción"
              error={error}
              value={action}
              onChange={(e, { value }) => setAction(value)}
              data={data?.multiAcciones}
              placeholder="Acción" />
            <Selector
              clearable={false}
              loading={loading}
              label="Elegir grupo usuario"
              error={error}
              value={userGroup}
              onChange={(e, { value }) => setUserGroup(value)}
              data={data?.multiGrupoUsuarios}
              placeholder="Grupo usuario" />
            <Selector
              clearable={false}
              loading={loading}
              label="Elegir módulo"
              error={error}
              value={module}
              onChange={(e, { value }) => setModule(value)}
              data={data?.multiModulos}
              placeholder="Módulo" />
            <Form.Dropdown
              selection
              label="Elegir estado"
              value={status}
              onChange={(e, { value }) => setStatus(value)}
              options={options} />
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button
            disabled={
              loadingSave ||
              !status.trim() ||
              !module.trim() ||
              !action.trim() ||
              !userGroup.trim()
            } onClick={handleMutation} content="Actualizar" />
          <Button onClick={onClose} content="Cancelar" />
        </Modal.Actions>
      </Modal>
    </>
  );
};

const UPDATE_PERMIT = gql`
  mutation updatePermit($input: ActualizarMultiPermisoInput!) {
    actualizarMultiPermiso(input: $input) {
      id
      estado
      multiAccion {
        id
        nombre
      }
      multiModulo {
        id
        nombre
      }
      multiGrupoUsuario {
        id
        nombre
      }
    }
  }
`;

export default UpdatePermit;