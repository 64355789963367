import Login from "./Pages/Login";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Dashboard from "./Pages/Dashboard";
import ProtectedRoute from "./Pages/ProtectedRoute";

function App() {
  return (
    <Router>
      <Switch>
        <Route
          exact
          path="/"
          name="Login Page"
          component={Login} />
        <ProtectedRoute
          path="/dashboard"
          name="Dashboard"
          pathToRedirect="/"
          component={Dashboard}
        />
      </Switch>
    </Router>
  );
}

export default App;
