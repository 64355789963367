import { gql, useMutation } from "@apollo/client";
import React from "react";
import { Button } from "semantic-ui-react";
import Swal from "sweetalert2";


const Verify = ({ email }) => {


  const onCompleted = ({ reenvioCorreoVerificarCuenta }) => {
    Swal.fire({
      icon: 'success',
      title: 'Completado',
      text: reenvioCorreoVerificarCuenta
    });
  }

  const onError = ({ graphQLErrors }) => {
    if (graphQLErrors) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: graphQLErrors[0]?.message
      });
    } else {
      Swal.fire("Lo sentimos, ha ocurrido un error.");
    }
  };

  const [mutation] = useMutation(VERIFY_USER, { onCompleted, onError });

  const handleMutation = () => {
    Swal.fire({
      title: '¿Deseas realizar está acción?',
      showDenyButton: true,
      confirmButtonText: 'Aceptar',
      denyButtonText: 'Cancelar',
    }).then((result) => {
      if (result.isConfirmed) {
        mutation({ variables: { email } });
      }
    })
  }

  return (
    <Button
      onClick={handleMutation}
      size="mini"
      compact
      content="Reenviar verificar cuenta" />
  );

}

const VERIFY_USER = gql`
  mutation verifyUser($email: EmailAddress!){
   reenvioCorreoVerificarCuenta(email:$email)
  }
`

export default Verify;