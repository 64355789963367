import React, { useState } from "react";
import { gql, useMutation, useQuery } from "@apollo/client";
import { Dimmer, Loader, Message, Container, Segment, Form, Button, Divider, Header, Icon } from 'semantic-ui-react';
import CreateField from "./CreateField";
import MDEditor from '@uiw/react-md-editor';
import TypeInputs from "../../../../../Components/TypeInputs";
import moment from "moment";
import Swal from "sweetalert2";
import PublishDocument from "./Publish";

const Document = ({ match: { params: { id, idDocument } }, history }) => {
  const { loading, error, data, refetch } = useQuery(DOCUMENT_REQUEST, { variables: { idCategoria: id, id: idDocument }, fetchPolicy: "network-only" });

  const handleBack = () => {
    history.replace("/dashboard/Categoría/" + id);
  }

  if (loading) return (
    <div>
      <Dimmer active inverted>
        <Loader size='large'>Cargando</Loader>
      </Dimmer>
    </div>
  );

  if (error) return (
    <div style={{ margin: 30 }}>
      <Header as="h1" style={{ margin: 20 }}>
        <Icon onClick={handleBack} link size='big' name='arrow left' />
        Documento
      </Header>
      <Message>Ha ocurrido un error.</Message>
    </div>
  );

  return (
    <>
      {data?.multiDocumento ? (
        <Content
          handleBack={handleBack}
          refetch={refetch}
          data={data?.multiDocumento} />
      ) :
        <>
          <Header as="h1" style={{ margin: 20 }}>
            <Icon onClick={handleBack} link size='big' name='arrow left' />
            Documento
          </Header>
          <Message>
            El documento al que intentas acceder no existe.
          </Message>
        </>}
    </>
  );
}


const Content = ({ data, refetch, handleBack }) => {
  const fillDatePart = (datePart) => {
    return datePart < 10 ? "0" + datePart : datePart;
  }
  const convertDate = (date) => {
    let _date = new Date(date);
    return _date.getFullYear() + "-" + fillDatePart(_date.getMonth() + 1) + "-" + fillDatePart(_date.getDate());
  }

  const documentType = data?.tipoDocumento?.valorParametro;
  const [name, setName] = useState(data?.nombre || "");
  const [enunciated, setEnunciated] = useState(data?.enunciado || "");
  const [description, setDescription] = useState(data?.descripcion || "");
  const [date, setDate] = useState(data?.fechaInicio ? data.fechaInicio.split("T")[0] : "");
  const [finalDate, setFinalDate] = useState(data?.fechaCaducidad ? convertDate(data.fechaCaducidad.split("T")[0]) : "");
  const [msjDate, setMsjDate] = useState(data?.mensajeFechaInicio || "");
  const [msjFinalDate, setMsjFinalDate] = useState(data?.mensajeCaducidad || "");
  const [msjOpenValue, setMsjOpenValue] = useState(data?.mensajeValorAbierto || "");
  const [msjConfirmation, setMsjConfirmation] = useState(data?.mensajeConfirmacion || "");
  const [msjRequestMailed, setMsjRequestMailed] = useState(data?.mensajeCorreoSolicitudEnviada || "");
  const [amount, setAmount] = useState(data?.valor || 0);
  const [studentLimit, setStudentLimit] = useState(data?.limiteRespuesta || 0);
  const [typeDocumentPayment, setTypeDocumentPayment] = useState(data?.tipoDocumentoPago?.id || "");
  const [loadingMutation, setLoadingMutation] = useState(false);
  const { data: response, loading, error } = useQuery(FIELDS);
  const onCompleted = async () => {
    setLoadingMutation(false);
    await refetch();
    Swal.fire('El documento actualizado exitosamente.', '', 'success');
  }

  const onError = ({ graphQLErrors }) => {
    setLoadingMutation(false);
    if (graphQLErrors?.length > 0) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: graphQLErrors[0]?.message
      });
    } else {
      Swal.fire("Lo sentimos, ha ocurrido un error.");
    }
  };
  const [mutation] = useMutation(UPDATE_DOCUMENT, { onCompleted, onError });

  const handleMutation = () => {
    setLoadingMutation(true);
    const input = {
      id: data?.id,
    };



    if (name.trim()) input.nombre = name;
    if (enunciated.trim()) input.enunciado = enunciated;
    if (description.trim()) input.descripcion = description;
    if (documentType === "Pago") input.idTipoDocumento = "8";
    if (documentType === "Solicitud") input.idTipoDocumento = "9";
    if (date.trim()) {
      const fechaInicio = new Date(moment(date));
      fechaInicio.setHours(0, 0, 0, 0);
      input.fechaInicio = fechaInicio.toISOString();
    }
    if (finalDate.trim()) {
      const fechaCaducidad = new Date(moment(finalDate));
      fechaCaducidad.setHours(23, 59, 59, 0);
      input.fechaCaducidad = fechaCaducidad.toISOString();
    }
    if (msjDate.trim()) input.mensajeFechaInicio = msjDate;
    if (msjFinalDate) input.mensajeCaducidad = msjFinalDate;
    if (msjConfirmation.trim()) input.mensajeConfirmacion = msjConfirmation;
    if (msjRequestMailed.trim()) input.mensajeCorreoSolicitudEnviada = msjRequestMailed;
    if (msjOpenValue.trim()) input.mensajeValorAbierto = msjOpenValue;

    if (studentLimit){
      let useIn = 0;

      data?.campos.forEach(campo => {
        if (campo.tipo.id === "117") {
          campo.opciones.forEach(opcione => {
            useIn = useIn + opcione.valor
          });
        }
      });

      const result = parseInt(studentLimit) - useIn;

      if (result < 0) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'El límite de estudiantes máximo es equivalente a lo usado en la opción aforo. Si desea disminuir el límite de estudiantes, debe realizarlo en la sección "Opción - Aforo".'
        });
        return;
      }

      input.limiteRespuesta = parseInt(studentLimit);
    } 
    if (typeDocumentPayment.trim()) input.idTipoDocumentoPago = typeDocumentPayment;
    if (typeDocumentPayment === "111" || typeDocumentPayment === "112") input.valor = parseFloat(amount);
    mutation({ variables: { input } });
  }

  return (
    <Container text>
      <Header as="h1" style={{ margin: 20 }}>
        <Icon onClick={handleBack} link size='big' name='arrow left' />
        Documento
      </Header>
      <Form style={{ margin: 20 }}>
        <PublishDocument id={data?.id} status={data?.estado} />
      </Form>
      <Segment style={{ margin: 20 }}>
        <Form >
          <Form.Group widths={2}>
            <Form.Input
              label="Nombre"
              value={name}
              onChange={(e, { value }) => setName(value)}
              fluid size='huge'
              placeholder='Escriba un nombre para su documento' />
            <Form.Input
              label="Enunciado"
              value={enunciated}
              onChange={(e, { value }) => setEnunciated(value)}
              fluid size='huge'
              placeholder='Escriba un enunciado para su documento' />
            <Form.Input
              label="Limite de estudiantes"
              value={studentLimit}
              onChange={(e, { value }) => setStudentLimit(value)}
              fluid size='huge'
              type="number"
              min={0}
              placeholder='Escriba un limite de cupos para este documento.' />
          </Form.Group>
          <Form.Group widths={2}>
            <Form.Input
              label="Fecha Inicio"
              value={date}
              onChange={(e, { value }) => setDate(value)}
              type="date" />
            <Form.Input
              label="Fecha de caducidad"
              value={finalDate}
              onChange={(e, { value }) => {
                console.log(value)
                setFinalDate(value)
              }}
              type="date" />
          </Form.Group>
          <Form.Group widths={2}>
            <Form.TextArea
              label="Mensaje fecha inicio"
              value={msjDate}
              onChange={(e, { value }) => setMsjDate(value)} />
            <Form.TextArea
              value={msjFinalDate}
              onChange={(e, { value }) => setMsjFinalDate(value)}
              label="Mensaje de caducidad" />
          </Form.Group>
          {documentType === "Solicitud" &&
            (<Form.Group widths={"equal"}>
              <Form.TextArea
                value={msjConfirmation}
                onChange={(e, { value }) => setMsjConfirmation(value)}
                label="Mensaje de confirmación" />
              <Form.TextArea
                value={msjRequestMailed}
                onChange={(e, { value }) => setMsjRequestMailed(value)}
                label="Mensaje de solicitud por correo" />
            </Form.Group>
            )}
          {documentType === "Pago" && (
            <>
              <Form.Group widths={"equal"}>
                <Form.Dropdown
                  selection
                  clearable
                  fluid
                  placeholder="Seleccione tipo pago"
                  loading={loading}
                  error={error}
                  label="Tipo de pago"
                  value={typeDocumentPayment}
                  onChange={(e, { value }) => setTypeDocumentPayment(value)}
                  options={response?.tipoDocumentoPago || []} />
                {(typeDocumentPayment === "111" || typeDocumentPayment === "112") && (
                  <Form.Input
                    label="Valor del documento"
                    value={amount}
                    error={parseFloat(amount) < 12000 ? "El monto minimo debe ser $12.000" : null}
                    onChange={(e, { value }) => setAmount(value)}
                    type="number" />
                )}
              </Form.Group>
              {(typeDocumentPayment === "110" || typeDocumentPayment === "112") && (
                <Form.TextArea
                  label="Mensaje valor abierto (Opcional)"
                  value={msjOpenValue}
                  onChange={(e, { value }) => setMsjOpenValue(value)} />
              )}
            </>
          )}
        </Form>
        <div style={{ marginTop: 20 }}>
          <h5>Descripción</h5>
          <MDEditor
            value={description}
            onChange={setDescription}
          />
          <br />
          <br />
          <Button loading={loadingMutation} onClick={handleMutation} style={{ backgroundColor: "#045A73", color: "white" }} content="Guardar" />
          <Divider />
          <div style={{ marginTop: 20 }}>
            <h2>Preguntas</h2>
            {
              data?.campos?.map((field, key) =>
                <TypeInputs refetch={refetch} input={field} key={field.id} limitAforo={studentLimit} />
              )
            }
            <Segment style={{ marginTop: 20 }}>
              <h4>Crear Pregunta</h4>
              <div style={{ textAlign: 'center' }}>
                <CreateField
                  refetch={refetch}
                  idDocument={data?.id}
                  campos={data?.campos}
                  trigger={
                    <Button circular style={{ backgroundColor: "#045A73", color: "white" }} icon="add" />
                  } />
              </div>
            </Segment>
          </div>
        </div>
      </Segment>
    </Container>
  )
}
const DOCUMENT_REQUEST = gql`
query Document($id: ID! $idCategoria: ID!) {
  multiDocumento(id: $id idCategoria:$idCategoria) {
    id
    nombre
    estado
    enunciado
    descripcion
    limiteRespuesta
    fechaInicio
    fechaCaducidad
    mensajeCaducidad
    mensajeFechaInicio
    mensajeConfirmacion
    mensajeCorreoSolicitudEnviada
    mensajeValorAbierto
    tipoDocumentoPago {
      id
    }
    tipoDocumento {
      id
      valorParametro
    }
    valor
    campos {
      id
      nombre
      obligatorio
      estado
      opciones {
        id
        nombre
        enunciado
        valor
        orden
      }
      tipo {
        id
        valorParametro
      }
      enunciado
    }
  }
}`;

const UPDATE_DOCUMENT = gql`
  mutation updateDocument($input: ActializarMultiDocumentoInput!) {
    actualizarMultiDocumento(input: $input) {
      id
      nombre
      limiteRespuesta
      enunciado
      valor
      tipoDocumento {
        id
        valorParametro
      }
      tipoDocumentoPago {
        id
        valorParametro
      }
      fechaInicio
      fechaCaducidad
      mensajeCaducidad
      mensajeConfirmacion
      mensajeFechaInicio
      mensajeCorreoSolicitudEnviada
      mensajeValorAbierto
    }
  }
`;


const FIELDS = gql`
  query {
    procedencia: multiValorParametros(
      filtro: { estado: ACTIVO, idParametro: 1 }
    ) {
      value: id
      text: valorParametro
      key: id
    }
    tipoDocumentoPago: multiValorParametros(
      filtro: { estado: ACTIVO, idParametro: 13 }
    ) {
      value: id
      text: valorParametro
      key: id
    }
  }
`;

export default Document;